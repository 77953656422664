import React, { useState } from 'react';
import { Form, Input, Button, Switch, Divider, Modal } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, BellOutlined } from '@ant-design/icons';
// TODO: Change the color of form labels to white
// This can be done by adding a style prop to each Form.Item or by using CSS

const Settings = () => {
  const [form] = Form.useForm();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const onFinish = (values) => {
    console.log('Success:', values);
    // Here you would typically send the updated values to your backend
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const handleDeleteAccount = () => {
    // Here you would typically send a request to delete the account
    console.log('Account deleted');
    setIsDeleteModalVisible(false);
  };
  const CustomInput = ({ placeholder, lable, icon }) => {
    return (
      <div className='flex flex-col'>
        <lable className="pb-2 text-white uppercase">{lable}</lable>
        <div className='absolute top-9 left-4'>
          {icon}
        </div>
        <input className='bg-transparent text-white outline-none border-[1px] px-10 py-1 rounded-sm' placeholder={placeholder} />
      </div>
    )
  }

  return (
    <div className='flex flex-col justify-center px-20 py-20'>
      <h1 className='text-xl uppercase text-white font-extralight' >Settings</h1>

      <Divider orientation="left" style={{ color: 'white', borderColor: 'black' }}></Divider>
      <Form form={form} name="settings" onFinish={onFinish} layout="vertical">
        <Form.Item name="name" rules={[{ message: 'Please input your name!' }]}>
          <CustomInput icon={<UserOutlined className='text-white' />} placeholder={"name"} lable={"given name"} />
        </Form.Item>
        <Form.Item name="email" rules={[{ type: 'email', message: 'Please input a valid email!' }]}>
          <CustomInput icon={<MailOutlined className='text-white' />} placeholder={"Email"} lable={"given name"} />
        </Form.Item>
        <Form.Item name="password" style={{ color: 'white' }}>
        <CustomInput icon={<LockOutlined className='text-white' />} placeholder={"password"} lable={"New Password (Optional)"} />
        </Form.Item>

        <Divider orientation="left" style={{ color: 'white', borderColor: 'black' }}></Divider>
        <div className='flex flex-row items-baseline'>
          <p className='text-white mr-2 font-extralight '>Email Notifications</p>
          <Form.Item name="emailNotifications" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
        <div className='flex flex-row items-baseline'>
          <p className='text-white mr-2 font-extralight '>App Notifications</p>
          <Form.Item name="appNotifications" valuePropName="checked" style={{ color: 'white' }}>
            <Switch />
          </Form.Item>
        </div>

        <Form.Item>
          <Button className="text-white bg-transparent border-[#6904E9] hover:!text-white w-[100%] " htmlType="submit">
            Save Changes
          </Button>
        </Form.Item>
      </Form>
      <Button className='!text-white border-[red] hover:!bg-[red] bg-transparent' danger  onClick={showDeleteModal}>
        Delete Account
      </Button>

      <Modal
        title={<span style={{ color: 'red' }}>Delete Account</span>}
        visible={isDeleteModalVisible}
        onOk={handleDeleteAccount}
        onCancel={() => setIsDeleteModalVisible(false)}
        okText="Yes, Delete My Account"
        cancelText="Cancel"
        okButtonProps={{ style: { backgroundColor: 'red', borderColor: 'red' } }}
        centered={true}
      // style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <p style={{ color: 'black' }}>Are you sure you want to delete your account? This action cannot be undone.</p>
      </Modal>
    </div>
  );
};

export default Settings;
