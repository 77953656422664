import React, { useState } from "react";
import first from "../../assets/images/first2.png";
import T from "../../assets/images/T.svg";
import TabSelector from "./components/ActiveTab";

import { Carousel } from 'antd';
import GPTQuestions from "../GPTQuestions";
import { message } from "antd";
import { useTrivia } from "../../contexts/TriviaContext";
import { useGame } from "../../contexts/GameContext";
import useTriviaQuestionCreator from "../../customHooks/useTriviaQuestionCreator";
import { useFeud } from "../../contexts/FeudContext";
import { parseTriviaData, parseTrivia, parseTriviaDataGPT } from './utils/index'
import OnNext from "./components/WithMetaForm";
import OnEditFinish from "./components/OnEdit";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';



export default function Index() {
  // Trivia Game Input Selection
  let { tabActive, changeTab, gameTypeRef } = useGame()
  const carouselRef = React.useRef(null);

  const [messageApi, contextHolder] = message.useMessage();

  const {
    handleAdditionFromText,
  } = useTrivia();
  const {
    handleAdditionFromTextFeud,
  } = useFeud();

  const onNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next(); // Move to the next slide
    }
    else {
      console.log("no next");

    }
  };


  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  const { createQuestionWithData, updateQuestionWithData } =
    useTriviaQuestionCreator();

  const info = () => {
    messageApi.error("Please Use the appropriate format");
  };

  const convert = async (text) => {
    let news = await parseTriviaDataGPT(text)
    console.log(news);

    // parseTriviaData
    let texts = null;
    if (gameTypeRef.current === "type_choice") {
      try {
        texts = await parseTrivia(text);

        let temp = [];

        texts.map((text, index) => {
          // Extract the answer letter and format the answer
          let answerMatch = text.answer.match(/Answer:\s([a-d])\)/i);
          let formattedAnswer = answerMatch
            ? `Answer: ${answerMatch[1]})`
            : text.answer;

          let arch = {
            id: index + 1,
            question: text.question,
            options: [],
            answer: formattedAnswer,
            open: false,
          };

          text.options.map((item) => {
            arch.options.push(item);
          });

          temp.push(arch);
        });

        handleAdditionFromText(temp);
        changeTab("one");
      } catch (error) {
        console.log(error);
        info();
      }
    } else {
      try {
        texts = await parseTriviaData(text);
        console.log(texts);

        handleAdditionFromTextFeud(texts);
        changeTab("one");

      } catch (error) {

        console.log(error)
      }
    }
  };


  return (
    <div className="prompt-cont">
      {contextHolder}
      <div className="">
        <div className="flex flex-col">
          <TabSelector
            firstIcon={first}
            secondIcon={T}
          />
          {["Data Exists"].map((_, __) => {
            if (tabActive == "one") {
              return (
                <Carousel ref={carouselRef} afterChange={(e) => console.log(e)}>
                  <div className="w-[100%] md:w-[80%]  bg-[#1D1E23] rounded-l-xl">
                    <DndProvider backend={HTML5Backend}>
                      <GPTQuestions
                      onNext={onNext}
                    />
                    </DndProvider>
                  </div>
                  <OnEditFinish />
                </Carousel>
              );
            } else if (tabActive == "two") {
              return (
                <OnNext />
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
}
