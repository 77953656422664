import React from 'react';
import { RetDropdonwGame } from '../../common/renderCollections';
import { useGame } from '../../../contexts/GameContext';

export default function OnNext() {
  // console.log(convert);
  const { textAreaInput, setConvertInput, convert, gameTypeRef, changeGameType } = useGame()

  const placeholderChange = (item) =>{
    if (item == 'type_choice'){
      return `Enter question and options like:\n1. What is the past tense of 'run'?\na) runned\nb) ran\nc) runned\nd) rung\nAnswer: b) ran \n\nQuestion Number and Option Latter are not required`
    } else if (item == 'type_feud'){
      return `[Q] ቁርስ ላይ ሊበላ የሚችል የኢትዪጵያ ምግብ ጥቀሱ\nጨጨብሳ | Chechebsa ((100))\nቡላ | Bula ((90))\nቅንጬ | ቂንጬ | Kinche | Kenche | Qinche | Qenche ((80))\nገንፎ | Genfo ((70))\nፍርፍር | Firfir ((60))\nቃተኛ | Kategna ((50))\nጭኮ | Chiko ((40))\nአምባሻ | Ambasha ((30))`
    } else {
      return `[Q] ቁርስ ላይ ሊበላ የሚችል የኢትዪጵያ ምግብ ጥቀሱ\nጨጨብሳ | Chechebsa ((100))`
    }
  }

  return (
    <div className="flex flex-row justify-center w-[100%]">
      <div className="w-[90%] md:w-[85%] h-[100%] pt-8">
        <div className="w-[100%] border-b-[1px] border-black px-1 py-2 border-t-[1px]">
          {RetDropdonwGame(gameTypeRef.current, changeGameType)}
        </div>
        <textarea
          value={textAreaInput}
          onChange={setConvertInput}
          className="w-[100%] h-[80%] p-10 bg-[#2E3340] rounded-xl outline text-white mx-7 my-5"
          placeholder={placeholderChange(gameTypeRef.current)}
          rows="10"
          cols="50"
        />
        <div className="flex flex-row justify-between mx-10 w-[100%]">
          <button
            onClick={() => convert(textAreaInput)}
            className="mx-[45%] bg-[#2E3340] text-white px-5 py-2"
          >
            Convert
          </button>
        </div>
        
      </div>
    </div>
  );
}
