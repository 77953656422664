import React, { useEffect, useState } from "react";
import TriviaList from "../TriviaList/TriviaList";
import { Spin, Button } from "antd";
import { useTrivia } from "../../contexts/TriviaContext";
import first from "../../assets/images/first2.png";
import TriviaListFeud from "../TriviaList/TriviaListFeud";
import { useFeud } from "../../contexts/FeudContext";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  generationButtonCollection,
  renderCustomInput,
  RetDropdonwGame,
  getPriveate,
  renderGenerateAndAdd,
} from "../common/renderCollections";
import { useGame } from "../../contexts/GameContext";


const event = ({ action, category, label, value }) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};


export default function Index({ onNext }) {
  const { gameTypeRef, onPrivecyChange, changeGameType, gameType } = useGame()
  const [qAmmount] = useState(10);
  const [prompt, setPrompt] = useState("");
  const [promptFeud, setPromptFeud] = useState("");
  const [changeGame, setChangeGames] = useState('type_choice')
  const endRef = React.useRef(null);



  const {
    handleGenerateTrivia,
    isLoading,
    triviaQuestionCache,
    addtotriviacachecustom,

  } = useTrivia();

  const {
    handleGenerateFeud,
    isLoadingFeud,
    feudQuestionCache,
    addtoFeudcachecustomFeud,

  } = useFeud();



  const scrollToBottom = () => {
    endRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleGenerateClick = async () => {
    if (prompt) {
      await handleGenerateTrivia(prompt, qAmmount);
      event({
        action: 'generate',
        category: 'trivia',
        label: 'Generate Trivia',
        value: prompt,
      });
      scrollToBottom();
    }
  };

  const handleGenerateFeudClick = async () => {
    if (promptFeud) {
      await handleGenerateFeud(promptFeud, qAmmount, "", gameTypeRef.current);
      event({
        action: 'generate',
        category: 'feud',
        label: 'Generate Feud',
        value: promptFeud,
      });
      scrollToBottom();
    }
  };

  const handleInputChange = (e) => {
    setPrompt({});
    setPrompt(e.target.value);
  };

  const handleInputChangeFeud = (e) => {
    setPromptFeud({});
    setPromptFeud(e.target.value);
  };


  React.useEffect(() => {
    setChangeGames(gameTypeRef.current)

  }, [gameTypeRef.current, changeGame])



  return (
    <div className=" flex flex-col justify-center items-center w-[100%] ">

      {isLoading && triviaQuestionCache.length < 0 ? (
        <div className="flex flex-row justify-center w-[59vw] py-3 rounded-md">
          <Spin />
        </div>
      ) : (
        <div className="w-[100%] mt-5">
          {gameType === "type_choice" ? (
            <TriviaList />
          ) : (
            <TriviaListFeud
            />
          )}
        </div>
      )}
      <div className="flex flex-row justify-between items-center w-[80%] mb-10">
        {(triviaQuestionCache?.length > 0 && triviaQuestionCache[0]?.question) || (feudQuestionCache?.length > 0 && feudQuestionCache[0]?.description) ? renderGenerateAndAdd(
          gameTypeRef.current,
          endRef,
          addtotriviacachecustom,
          isLoading,
          handleGenerateClick,
          isLoadingFeud,
          addtoFeudcachecustomFeud,
          handleGenerateFeudClick
        ) : null}

        <div className="">
          {(triviaQuestionCache?.length > 0 && triviaQuestionCache[0]?.question) || (feudQuestionCache?.length > 0 && feudQuestionCache[0]?.description) ? <Button
            onClick={onNext}
            className="px-5 hover:!text-white  ml-5 rounded-2xl !bg-[#414550] hover:!outline-none !border-0 text-white !outline-none"
          >
            <FontAwesomeIcon icon={faForward} color="white" size={"md"} />
          </Button> : null}</div>

      </div>
        <div className="flex flex-row  justify-between px-10 w-[82%]  mx-5  mb-20">
          {renderCustomInput(
            gameTypeRef.current,
            prompt,
            handleInputChange,
            promptFeud,
            handleInputChangeFeud
          )}
          {RetDropdonwGame(gameTypeRef.current, changeGameType)}
          {generationButtonCollection(
            prompt,
            gameTypeRef.current,
            isLoading,
            first,
            isLoadingFeud,
            handleGenerateClick,
            handleGenerateFeudClick
          )}
        </div>

      <div ref={endRef}></div>
    </div>
  );
}
