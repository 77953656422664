import React, { createContext, useState, useContext } from 'react';

// Create the context
const FeudContext = createContext();

function removeNewlinesAndHashtagsAtEnd(sentence) {
  if (sentence) {
    return sentence.replace(/[\n#]+$/g, '');
  } else { return null }
}

let initialData = [
  { index: 1, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 2, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 3, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 4, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 5, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 6, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  { index: 7, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
]
// Custom hook for easy context consumption
export const useFeud = () => useContext(FeudContext);
function convertObjectToArray(obj) {
  return Object.entries(obj).map(([key, value]) => ({
    key: key,
    value: parseInt(value)
  }));
}
// Provider component
export const FeudProvider = ({ children }) => {
  const [isLoadingFeud, setIsLoadingFeud] = useState(false);
  const [feudQuestions, setFeudQuestions] = useState([]);
  const [feudQuestionCache, setFeudQuestionsCache] = useState([
    { index: 1, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 2, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 3, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 4, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 5, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 6, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    { index: 7, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    // { index: 8, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    // { index: 9, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
    // { index: 10, description: null, options: ['', '', '', ''], answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, custom: true },
  ]);
  const [excluded, setExcluded] = useState([]);
  let [rendered, setRendered] = useState(1);
  const [errorFeud, setErrorFeud] = useState(null);
  const findMaxIndex = (item) =>{
    let max = 0
    item.map((item, _) =>{
      if(item.index > max){
        max = item.index
      }
    })
    return max
  }
  let resetFeudState = async () => {
      setFeudQuestionsCache(initialData)
  }

  let handleGenerateFeud = async (prompt, qnumber, page, gameType) => {
    let current = await findMaxIndex(feudQuestionCache)
    const url = gameType === 'type_feud' ? 
        'https://radiant-basin-54000-27b04cfd1733.herokuapp.com/api/trivia/generate-feud' : 
        'https://radiant-basin-54000-27b04cfd1733.herokuapp.com/api/trivia/generate-quiz';

    setIsLoadingFeud(true);
    setErrorFeud(null);

    const headersList = { "Content-Type": "application/json" };
    const bodyContent = JSON.stringify({
        prompt,
        exclude: excluded,
        expect: 10,
        count: feudQuestionCache.length
    });

    try {
        const response = await fetch(url, {
            method: "POST",
            body: bodyContent,
            headers: headersList
        });

        const data = await response.text();
        const newData = JSON.parse(data);
        const ToSet = [];

        const temp = newData.questions;
        let cheker = feudQuestionCache;

        temp.forEach((item, index) => {
            const existingQuestion = cheker.find(question => question.description === null);

            if (existingQuestion) {
                cheker = cheker.filter(q => q.index !== existingQuestion.index);
                const updatedQuestion = {
                    ...item,
                    description: removeNewlinesAndHashtagsAtEnd(item.description),
                    answerPoints: convertObjectToArray(item.answerPoints),
                    index: existingQuestion.index,
                    open: false
                };
                ToSet.push(updatedQuestion);
            } else {
                const newQuestion = {
                    ...item,
                    description: removeNewlinesAndHashtagsAtEnd(item.description),
                    answerPoints: convertObjectToArray(item.answerPoints),
                    index: current + 1,  // Use the unique index here
                    question: removeNewlinesAndHashtagsAtEnd(item.question),
                    open: false
                };
                ToSet.push(newQuestion);
                current  = current + 1;
                // setUniqueIndex(uniqueIndex + 1);  // Increment the unique index
            }
        });

        setFeudQuestions(ToSet);
        setFeudQuestionsCache(prev => [...prev.filter(question => question.description !== null), ...ToSet]);
        const toExclude = temp.map(question => question.description);
        setExcluded(oldExclude => [...oldExclude, ...toExclude]);
        setRendered(rendered + 1);
    } catch (e) {
        console.error('There was an error fetching the trivia questions:', e);
        setErrorFeud(e);
    }

    setIsLoadingFeud(false);
  };

  const moveCard = (fromIndex, toIndex) => {
    const updatedQuestions = [...feudQuestionCache];
    const [movedCard] = updatedQuestions.splice(fromIndex, 1); // Remove the card from its current position
    updatedQuestions.splice(toIndex, 0, movedCard); // Insert it at the new position
    setFeudQuestionsCache(updatedQuestions); // Update the state with the reordered list
  };
  

  const handleAdditionFromTextFeud = async (data) => {
    let ToSet = []
    let i = feudQuestionCache[feudQuestionCache.length - 1].index
    
    data.map((item, index) => {
      let temp = { ...item, index: index + 1 + i, open: false }
      ToSet.push(temp);
    })
    setFeudQuestionsCache(prev => [...prev.filter(question => question.description !== null), ...ToSet]);


  }

  const openToggleFeud = (index) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === index) {
        return { ...item, open: !item.open };
      } else {
        return item;
      }
    }));
  };

  const updateQuestionInCacheFeud = (questionId, updatedDescription) => {
    const updatedQuestions = feudQuestionCache.map((item) => {
      if (item.index === questionId) {
        return { ...item, description: updatedDescription };
      }
      return item;
    });
    setFeudQuestionsCache(updatedQuestions);
  };
  const updateFeudQuestionsOptionPoint = (questionId, optionIndex, updatedValue) => {
    console.log(questionId, optionIndex, updatedValue);
    setFeudQuestionsCache(feudQuestionCache.map((item, index) => {
      if (item.index === questionId) {
        let answerPoint = item.answerPoints
        answerPoint[optionIndex].value = parseInt(updatedValue)
        return item
      } else { return item }
    }))

  }


  const updateGiveQuestionOptionFeud = (questionId, optionIndex, updatedOption) => {
    const updatedFeudQuestionsAP = feudQuestionCache.map((item, index) => {
      if (item.index === questionId) {
        let answerPoint = item.answerPoints
        answerPoint[optionIndex].key = updatedOption
        return item
      } else { return item }

    })
    setFeudQuestionsCache(updatedFeudQuestionsAP);
  };

  const deleteGiveQuestionOptionsFeud = (questionId, optionIndex) => {
    const updatedQuestions = feudQuestionCache.map((item) => {
      if (item.index === questionId) {
        delete item.answerPoints[optionIndex];
        return { ...item };
      }
      return item;
    });
    setFeudQuestionsCache(updatedQuestions);
  };

  const changeAnswereFeud = (questionId, updatedAnswereID) => {
    const updatedQuestions = feudQuestionCache.map((item) => {
      if (item.index === questionId) {
        return { ...item, answer: item.options[updatedAnswereID] };
      }
      return item;
    });
    setFeudQuestionsCache(updatedQuestions);
  };

  const mergeArraysAndDownloadFeud = () => {
    const newTriviaForJson = feudQuestionCache.map((item) => {
      delete item['open'];
      delete item['index'];
      return item;
    });

    const dataToDownload = { data_id: '', data: { questions: newTriviaForJson } };
    const dataStr = JSON.stringify(dataToDownload, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'triviaQuestions.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const deleteQuestionFeud = (questionId) => {
    let updatedQuestions = feudQuestionCache.filter((item) => item.index !== questionId);
    setFeudQuestionsCache(updatedQuestions);
  };

  const handleCustomInputCloseFeud = (id) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === id) {
        return { ...item, open: false };
      } else {
        return item;
      }
    }));
  };

  const handleCustomInputOpenFeud = (id) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === id) {
        return { ...item, open: !item.open };
      } else {
        return item;
      }
    }));
  };

  const addtoFeudcachecustomFeud = () => {
    let length = feudQuestionCache.length;
    let newAddition = feudQuestionCache[length - 1].index;
    setFeudQuestionsCache([...feudQuestionCache, { index: newAddition + 1, description: null, answerPoints: [{ key: "", value: "" }], imageUrl: "", open: false, type: 'type_feud' }]);
  };

  const handleCustomQuestionChangeFeud = (e, items) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        return { ...item, description: e.target.value };
      } else {
        return item;
      }
    }));
  };

  const handleCustomQuestionImageAddFeud = (e, items) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index == items.index) {
        item.imageUrl = e
        return item;
      } else {
        return item;
      }
    }));

  };

  const handleCustomQuestionImageRemoveFeud = (items) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        item.imageUrl = ''
        return item;
      } else {
        return item;
      }
    }));
  };

  const handleCustomOptionAdditionFeud = (items) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        let temp = [...item.answerPoints];
        temp.push({ key: "", value: "" });
        return { ...item, answerPoints: temp };
      } else {
        return item;
      }
    }));
  };

  const handleCustomOptionsChangeFeud = (e, items, index) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        let temp = [...item.options];
        temp[index] = e.target.value;
        return { ...item, options: temp };
      } else {
        return item;
      }
    }));
  };

  const handleCustomOptionsDeleteFeud = (e, items, index) => {
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        let temp = [...item.options];
        temp.splice(index, 1);
        return { ...item, options: temp };
      } else {
        return item;
      }
    }));
  };

  const handleCustomPointsChangeFeud = (e, items, index) => {
    console.log('feud points changed', e, items, index);
    setFeudQuestionsCache(feudQuestionCache.map((item) => {
      if (item.index === items.index) {
        let points = { ...item.answerPoints };
        points[e.target.name] = parseInt(e.target.value);
        return { ...item, answerPoints: points };
      } else {
        return item;
      }
    }));
  };

  const editGlobalFeaudquestions = (data) => {
    let temp = []
    data.map((item, index) => {
      item.index = index + 1
      item.answerPoints = convertObjectToArray(item.answerPoints)
      temp.push(item)
    })
    setFeudQuestionsCache(temp);

  }

  const value = {
    feudQuestions,
    feudQuestionCache,
    isLoadingFeud,
    openToggleFeud,
    handleGenerateFeud,
    updateQuestionInCacheFeud,
    updateGiveQuestionOptionFeud,
    deleteGiveQuestionOptionsFeud,
    changeAnswereFeud,
    mergeArraysAndDownloadFeud,
    deleteQuestionFeud,
    handleCustomInputCloseFeud,
    handleCustomInputOpenFeud,
    addtoFeudcachecustomFeud,
    handleCustomQuestionChangeFeud,
    handleCustomQuestionImageAddFeud,
    handleCustomQuestionImageRemoveFeud,
    handleCustomOptionAdditionFeud,
    handleCustomOptionsChangeFeud,
    handleCustomOptionsDeleteFeud,
    updateFeudQuestionsOptionPoint,
    handleCustomPointsChangeFeud,
    editGlobalFeaudquestions,
    errorFeud,
    resetFeudState,
    handleAdditionFromTextFeud,
    moveCard
  };

  return (
    <FeudContext.Provider value={value}>
      {children}
    </FeudContext.Provider>
  );
};
