import React, { useState, useEffect } from 'react';
import { List, Skeleton, Tag } from 'antd';
import moment from 'moment'
import { Input, Typography } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Search } = Input;
const { Text } = Typography;


const FilterTable = ({ filtered }) => {
    const [initLoading, setInitLoading] = useState(true);
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);
    const [selectedTags, setSelectedTags] = React.useState(['day', 'week']);
    const navigate = useNavigate()
    const handleChange = async (tag, checked) => {
        setInitLoading(true)

        const nextSelectedTags = await checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        await setSelectedTags(nextSelectedTags);
        let newData = list.filter((item, index) => {
            if (nextSelectedTags.includes(item.g)) {
                return item
            }
        })
        setData(newData)
        setInitLoading(false)

    }


    useEffect(() => {
        if (filtered) {
            setData(filtered)
            setList(filtered)
            setInitLoading(false)

        } else {
            setInitLoading(false)
        }
    }, [filtered]);

    const parseGameType = (data) => {
        let text = data.replace('-', " ")
        return text
    }

    return (
        <div className='w-[85%] !mx-20'>
            <div className='flex flex-row justify-between border-b-[0.5px] border-black mt-4'>
                <div className='flex flex-row items-center'>
                    <a onClick={() => navigate(-1)}><RollbackOutlined className="text-white text-xl mr-2 hover:text-[#4362a1]" /></a>
                    <h1 className='text-white font-bold uppercase py-4'>
                        Leader Board
                    </h1>
                </div>

                <div className='flex flex-row items-center bg-[#222222] py-1 px-1 '>
                    {["day", 'week'].map((tag) => (
                        <Tag.CheckableTag
                            className='uppercase border-2'
                            key={tag}
                            checked={selectedTags.includes(tag)}
                            onChange={(checked) => handleChange(tag, checked)}
                        >
                            <span className='text-white'>{tag}</span>
                        </Tag.CheckableTag>
                    ))}
                </div>
            </div>
            <List
                loading={initLoading}
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, _) => (
                    <List.Item
                        actions={[<a onClick={() => navigate('/games/list', { state: { id: item.id, start: item.sT, end: item.eT } })} className='hover:text-[#9a77c8] uppercase text-[#808480]' key="list-loadmore-edit">explore</a>]}
                    >
                        <Skeleton avatar title={false} loading={initLoading} active>
                            <List.Item.Meta
                                className=''
                                avatar={<div className='flex flex-col justify-center items-center h-20'>
                                    <Tag color="#2db7f5" className='uppercase'>{parseGameType(item.s)}</Tag>
                                </div>}
                                title={null}
                                description={
                                    <div className='flex flex-col'>
                                        <span className='text-white'>{moment(item.st).format('ddd MM YYYY')}</span>
                                        <p className='text-[#a5a4a4]'>{item.id}</p>
                                        <Tag className='uppercase text-white w-20 bg-[black] text-center text-xs mt-2 border-0'>{item.g}</Tag>
                                    </div>}
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />
        </div>
    );


};
export default FilterTable;