import React, { useEffect, useState } from "react";
import useMyTriviaList from "../customHooks/useMyTrivaList";
import { EditOutlined, DeleteOutlined, BulbOutlined, OrderedListOutlined, RollbackOutlined } from "@ant-design/icons";
import { Spin, message, Badge, Tag, Button } from "antd";
import { mains } from "./constants/routes";
import event from "../customHooks/useGoogleAnalyticsTrigger";
import { useNavigate } from "react-router-dom";
import { useGame } from "../contexts/GameContext";
import { useTrivia } from "../contexts/TriviaContext";
import { useFeud } from "../contexts/FeudContext";
import { Avatar, Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';



export default function MyTrivia({ onChange }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate()
  const { onEditChange, changeGameType } = useGame()
  const {
    myTriviaQuestions,
    isLoading,
    currentPage,
    totalPages,
    setCurrentPage,
  } = useMyTriviaList();
  const {
    editGlobalTrivaquestions,
  } = useTrivia();

  const { editGlobalFeaudquestions } = useFeud()




  const onChangePage = () => {
    let newPage = currentPage + 1
    setCurrentPage(newPage);
  };

  const deleteUploadLookupToQuestion = async (qustionToUpload) => {
    let main = await mains()
    event({
      action: 'Delete',
      category: 'Delete',
      label: `Delete`,
      value: qustionToUpload.element_id,
    });
    const url = main.API_USER_ELEMENT_GAME_DATA + `/trivia-question-list/elements/${qustionToUpload.element_id}`;

    const options = {
      method: "DELETE",
      headers: {
        "api-key": main.API_KEY,
        Accept: "application/json",
        Authorization:
          "Bearer fMnmHgaqcvs524DKhYGxuCbZWw7NXByLR6Fzt3TAEQdr9pkjPJ",
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.success) {
        window.location.reload();
        onChange("");
      } else {
        messageApi.info(`${data.message} `);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const triviaNavigate = () => {
    navigate('/gamelist')
    changeGameType('type_choice')
  }
  const feudNavigate = () => {
    navigate('/gamelist')
    changeGameType('type_feud')
  }
  const resolveName = (name) => {
    if (name == 'type_feud') {
      return 'Feud'
    } else {
      return 'Trivia'
    }
  }

  return (
    <div className="flex flex-col mx-10 my-5 items-center min-h-[80vh]">

      <div className="flex flex-row justify-between items-center w-[100%] mb-5 pb-5 border-b-[gray] border-b-[1px]">
        <div className="flex flex-row items-center">
          <a className="mr-5 shadow-sm shadow-black p-1" onClick={() => navigate(-1)}><RollbackOutlined className="text-white text-lg mr-2 hover:text-[#4362a1]" /></a>
          <h1 className="text-white text-xl font-medium text-left ">My Trivia List</h1>
        </div>
        <Button onClick={() => navigate('/gamelist')} className="text-white border-0 hover:text-white">Create New</Button>
      </div>

      {isLoading ? (
        <div className="flex flex-col mt-20 w-[50vw]">
          <Spin size={50} />
        </div>
      ) : (
        <div
          id="scrollableDiv"
          className="w-full h-[70vh]"
          style={{
            overflow: 'auto',
            padding: '0 16px',
            border: '1px solid rgba(140, 140, 140, 0.35)',
          }}
        >
          <InfiniteScroll
            dataLength={myTriviaQuestions.length}
            next={onChangePage}
            hasMore={myTriviaQuestions.length < totalPages}
            loader={
              <div>
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 1,
                  }}
                  active={myTriviaQuestions.length < totalPages}
                />
              </div>
            }
            endMessage={<Divider className="text-white" plain><h1 className="text-white uppercase">End 🤐</h1></Divider>}
            scrollableTarget="scrollableDiv"
          >
            <List
              dataSource={myTriviaQuestions}
              renderItem={(item) => (
                <List.Item >
                  <List.Item.Meta
                    avatar={<Tag icon={item?.data.tags.map((item, index) => {
                      if (["type_choice", "type_feud"].includes(item)) {
                        if (item == 'type_feud') {
                          return <OrderedListOutlined />
                        } else {
                          return <BulbOutlined />
                        }
                      }
                    })} color={item?.data.tags.map((ite, index) => {
                      if (["type_choice", "type_feud"].includes(ite)) {
                        if (ite === 'type_feud') {
                          return '#0482e1'
                        } else {
                          return '#704806'
                        }
                      }
                    })} >
                      {item?.data.tags.map((item, index) => {
                        if (["type_choice", "type_feud"].includes(item)) {
                          return <div className="text-xs">{resolveName(item)}</div>;
                        }
                      })}
                    </Tag>}
                    title={<h1 className="mr-2 text-sm uppercase text-white">{item.data.name}</h1>
                    }
                    description={<h1 className="text-xs text-[#858080]">{item.data.dataId}</h1>}
                  />
                  <div className="flex flex-row">
                    <a
                      onClick={() => onEditChange(item, editGlobalTrivaquestions, editGlobalFeaudquestions, triviaNavigate, feudNavigate)}
                      className="cursor-pointer hover:bg-[black] px-2 bg-[white] text-black rounded-lg"
                    >
                      <EditOutlined />
                    </a>
                    <a
                      onClick={() => deleteUploadLookupToQuestion(item)}
                      className="cursor-pointer hover:bg-[black] px-2 bg-red-700 mx-2 rounded-lg shadow-lg"
                    >
                      <DeleteOutlined className="text-white" />
                    </a>
                  </div>
                </List.Item>
              )}
            />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );





}
