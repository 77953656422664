import { Tooltip, Button, Radio } from "antd";
import React from "react";
import Custominput from "../GPTInput";
import { faAdd, faForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Switch } from 'antd';
import { Select } from 'antd';



export const getPriveate = (privecyTypeChange) => {
    return (
        <div className="m-2 ml-10">
            <Switch  onChange={(e) => privecyTypeChange(e)} checkedChildren="Public" unCheckedChildren="Private" className=" !rounded-sm" defaultChecked />
        </div>
    )
}

export const RetDropdonwGame = (gameType, gameTypeChange) => {

    return (<div className="m-1 ml-6">
        <Select
            defaultValue={gameType}
            style={{
                width: 110,
            }}
            onChange={(e) => gameTypeChange(e)}
            // dropdownStyle={{borderWidth: 0, color: 'white'}}
            options={[
                {
                    value: 'type_choice',
                    label: 'Trivia',
                },
                {
                    value: 'type_feud',
                    label: 'Feud',
                },
                {
                    value: 'type_quize',
                    label: 'Quiz',
                },
            ]}
        />
    </div>)
}

export const generationButtonCollection = (prompt, gameType, isLoading, first, isLoadingFeud, handleGenerateClick, handleGenerateFeudClick) => {
    return (
        <div>
            {prompt ? (
                <Tooltip
                    title={"tap generate to use AI"}
                    trigger="hover"
                    placement="right"
                    color="#2E313B"
                    defaultOpen
                >
                    {gameType === "type_choice" ? (
                        <Button
                            loading={isLoading}
                            onClick={() => handleGenerateClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    ) : (
                        <Button
                            loading={isLoadingFeud}
                            onClick={() => handleGenerateClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    )}
                </Tooltip>
            ) : (
                <Tooltip
                    title="Create Your Custom Question"
                    trigger="hover"
                    placement="right"
                    color="#2E313B"
                    defaultOpen
                >
                    {gameType === "type_choice" ? (
                        <Button
                            loading={isLoading}
                            disabled
                            onClick={() => handleGenerateFeudClick()}
                            className="flex flex-row  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    ) : (
                        <Button
                            loading={isLoadingFeud}
                            onClick={() => handleGenerateFeudClick()}
                            className="flex flex-row w-[20vw]  md:w-[9vw] justify-around items-center !border-0  rounded-sm shadow-md ml-5  mt-1 !text-[white] !bg-[#6904E9]"
                        >
                            <img alt="generate" src={first} className="w-5 h-5" />
                            Generate
                        </Button>
                    )}
                </Tooltip>
            )}
        </div>
    );
};

export const renderCustomInput = (gameType, prompt, handleInputChange, promptFeud, handleInputChangeFeud) => {
    if (gameType === "type_choice") {
        return (
            <Custominput
                prompt={prompt}
                handleInputChange={handleInputChange}
                gameType="trivia"
            />
        );
    } else if (gameType === "type_feud") {
        return (
            <Custominput
                prompt={promptFeud}
                handleInputChange={handleInputChangeFeud}
                gameType="feud"
            />
        );
    } else if (gameType === "type_quize") {
        return (
            <Custominput
                prompt={promptFeud}
                handleInputChange={handleInputChangeFeud}
                gameType="quize"
            />
        ); // Or handle the 'type_quize' case appropriately
    }
    return null;
};

export const renderGenerateAndAdd = (gameType, endRef, addtotriviacachecustom, isLoading, handleGenerateClick, isLoadingFeud, addtoFeudcachecustomFeud, handleGenerateFeudClick) => {
    return (
        <>
            {gameType === "type_choice" ? (
                <div
                    ref={endRef}
                    className="flex w-[100%] flex-row justify-between items-center"
                >
                    <button
                        onClick={() => addtotriviacachecustom()}
                        className="px-3 my-4 py-2 text-sm uppercase ml-5  rounded-3xl shadow-sm  bg-[#414550] cursor-pointer  text-[#fff]"
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" size={"md"} />
                    </button>
                    <Button
                        loading={isLoading}
                        onClick={() => handleGenerateClick()}
                        className="mr-[40%] text-white cursor-pointer px-5 py-1 text-xs rounded-md border-[1px] !bg-[transparent] hover:!text-[white] hover:!border-[#414550] border-[#414550] uppercase"
                    >
                        Generate more
                    </Button>
                </div>
            ) : (
                <div
                    ref={endRef}
                    className="flex w-[100%] flex-row justify-between items-center"
                >
                    <button
                        onClick={() => addtoFeudcachecustomFeud()}
                        className="px-3 my-4 py-2 text-sm uppercase ml-5  rounded-3xl shadow-sm  bg-[#414550] cursor-pointer  text-[#fff]"
                    >
                        <FontAwesomeIcon icon={faAdd} color="white" size={"md"} />
                    </button>
                    <Button
                        loading={isLoadingFeud}
                        onClick={() => handleGenerateFeudClick()}
                        className="mr-[40%] text-white cursor-pointer px-5 py-1 text-xs rounded-md border-[1px] !bg-[transparent] hover:!text-[white] hover:!border-[#414550] border-[#414550] uppercase"
                    >
                        Generate more
                    </Button>
                </div>
            )}</>
    )
}

export const renderHandleNextClick = (gameType, triviaQuestionCache, handleUploadToServer, feudQuestionCache, handleUploadToServerFeud) => {
    return <>
        {gameType === "type_choice" ? (
            <div
                className={`flex flex-row items-center  rounded-b-lg justify-center   ${triviaQuestionCache.length > 0 ? "py-5" : "py-0"
                    } `}
            >
                <div className="mr-10 w-[100%]">
                    {triviaQuestionCache.length > 0 ? (
                        <div className="flex flex-row justify-between w-[100%]">
                            <Button
                                onClick={handleUploadToServer}
                                className="px-5 hover:!text-white  ml-5 rounded-2xl !bg-[#414550] hover:!outline-none !border-0 text-white !outline-none"
                            >
                                <FontAwesomeIcon icon={faForward} color="white" size={"md"} />

                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        ) : (
            <div
                className={`flex flex-row items-center justify-center ] ${feudQuestionCache.length > 0 ? "py-5" : "py-0"
                    } `}
            >
                <div className="mr-10 w-[100%]">
                    {feudQuestionCache.length > 0 ? (
                        <div className="flex flex-row justify-between w-[100%]">
                            <Button
                                onClick={handleUploadToServerFeud}
                                className="px-5 hover:!text-white  ml-5 rounded-2xl !bg-[#414550] hover:!outline-none !border-0 text-white !outline-none"
                            >
                                <FontAwesomeIcon icon={faForward} color="white" size={"md"} />
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        )}</>
}

export const renderRadioButtonCollection = (gameType, gameTypeChange) => {
    return (
        <Radio.Group
            onChange={(e) => gameTypeChange(e)}
            className="bg-[] w-[99%]"
            name="radiogroup"
            defaultValue={"type_feud"}
            value={gameType}
        >
            <Radio className="text-white" value={"type_choice"}>
                Trivia
            </Radio>
            <Radio className="text-white" value={"type_feud"}>
                Feud
            </Radio>
            <Radio className="text-white" value={"type_quize"}>
                Quiz
            </Radio>
        </Radio.Group>
    )
}


export const renderPrivecy = (privecyType, privecyTypeChange) => {
    return (
        <Radio.Group
            onChange={(e) => privecyTypeChange(e)}
            className="w-[99%] "
            name="radiogroup"
            defaultValue={"access_public"}
            value={privecyType}
        >
            <Radio className="text-white" value={"access_public"}>
                Public
            </Radio>
            <Radio className="text-white" value={"access_private"}>
                Private
            </Radio>
        </Radio.Group>
    )
}