import React, { useState } from 'react';
import { mains } from '../components/constants/routes';
import axios from "axios";




const useLeaderboard = (id, page) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMain, setIsLoadingMain] = useState(true);
    const [myTriviaQuestions, setMyTriviaQuestions] = useState([]);
    const [buildLeaderFilter, setBuildLeaderFilter] = useState({
        "allData": [],
        "filterData": [],
        "gameTag": []
    })
    let [lookup, setLookup] = useState('')
    const [error, setError] = useState(false)
    const [activeLookups, setActiveLookup] = useState([])
    const [main, setMain] = useState(null)
    const [active, setActive] = useState([])

    const getYoutubeTagsTest = async(data) =>{
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://www.googleapis.com/youtube/v3/channels?id=${data}&key=AIzaSyBM5llH2jdJtZPdOOEa-L9FSfK0ZxM2RTM&part=snippet`,
                headers: {}
            };
            try {
                const response = await axios.request(config);
                console.log("log", response.data);
                return response.data;
            } catch (error) {
                return { error: error, data: [] };
            }
    }

    const getYoutubeTags = async (data) => {
        let bodyContent = JSON.stringify({
            "datastring": data
        });

        const options1 = {
            method: "GET",
            headers: {
                Accept: "application/json",
            },
            body: bodyContent,

        };
        try {
            let response = await fetch("http://localhost:5000/trivia-beemi/us-central1/processList", {
                method: "POST",
                body: bodyContent,
                headers: options1
            }); 
            const data1 = await response.json();
            console.log('data', data1);

            return data1

        } catch (error) {
            console.error(error);
            return []

        }
    }

    const parseYoutubeData = (data) => {
        let tempReturn = []
        data.map((item, index) => {
            tempReturn.push(item.uU)
        })
        return tempReturn.join(',')
    }
    const buildWithYoutube = async(local, found) =>{
        const dictB = await Object.fromEntries(found.map(item => [item.id, item]));        
        await local.forEach(itemA => {
            const matchingItem = dictB[itemA.uU];
            if (matchingItem) {
                Object.assign(itemA, matchingItem); // Add fields from listB into listA
            }
        });
        return local

    }


    const getMainActiv = async (id) => {
        let main = await mains()
        setMain(main.API_KEY)
        const url1 = `https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data/${id}`
        // main.API_USER_ELEMENT_GAME_DATA + `/trivia_leaderboard_titles_13cebe72-cc4d-46a7-8f14-7f7e112891d7`;
        const options1 = {
            method: "GET",
            headers: {
                "api-key": main.API_KEY,
                Accept: "application/json",
                Authorization:
                    "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
            },
        };
        try {
            const response1 = await fetch(url1, options1);
            const data1 = await response1.json();
            setActive(data1.data.leaderboard)
            let stringifyedList = await parseYoutubeData(data1.data.leaderboard)
            let Youtube = await getYoutubeTagsTest(stringifyedList)
            console.log(data1.data.leaderboard, Youtube.items);
            let newData = await buildWithYoutube(data1.data.leaderboard, Youtube.items)
            console.log('new parse', newData);
            

            return data1

        } catch (error) {
            console.error(error);
            return []

        }

    }

    const getFilteredData = async (gameType, day) => {
        let main = await mains()
        setMain(main.API_KEY)
        const url1 = `https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data/${id}`
        // main.API_USER_ELEMENT_GAME_DATA + `/trivia_leaderboard_titles_13cebe72-cc4d-46a7-8f14-7f7e112891d7`;
        const options1 = {
            method: "GET",
            headers: {
                "api-key": main.API_KEY,
                Accept: "application/json",
                Authorization:
                    "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
            },
        };
        try {
            const response1 = await fetch(url1, options1);
            const data1 = await response1.json();
            setActive(data1.data.leaderboard)
            setIsLoadingMain(false)

        } catch (error) {
            console.error(error);
            setIsLoadingMain(false)

        }

    }

    const getLeaderBoard = async () => {
        let main = await mains()
        setMain(main.API_KEY)


        const url = 'https://klive-main-backend-production-dfc169d2a67f.herokuapp.com/api/user-game-data/trivia_leaderboard_titles_13cebe72-cc4d-46a7-8f14-7f7e112891d7'
        // main.API_USER_ELEMENT_GAME_DATA + `/trivia_leaderboard_titles_13cebe72-cc4d-46a7-8f14-7f7e112891d7`;
        const options = {
            method: "GET",
            headers: {
                "api-key": main.API_KEY,
                Accept: "application/json",
                Authorization:
                    "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
            },
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();
            console.log("data is ", data);

            let tempFilter = {
                "group": [],
                "gameTag": []
            }
            await data?.data?.titles.map((item, _) => {
                if (!tempFilter?.group.includes(item.g)) {
                    tempFilter.group.push(item?.g)
                }
                if (!tempFilter.gameTag.includes(item?.s)) {
                    tempFilter.gameTag.push(item.s)
                }
            })

            setBuildLeaderFilter(prevData => ({
                ...prevData,
                allData: data?.data?.titles,
                group: tempFilter?.group,
                gameTag: tempFilter?.gameTag
            }))


            setLookup(data?.data)
            return data?.data?.titles
        } catch (error) {
            setIsLoading(false)
            setError(true)
            return []
        }
    };



    React.useEffect(() => {
        getLeaderBoard()
    }, [])

    return { isLoading, myTriviaQuestions, error, active, isLoadingMain, buildLeaderFilter, getMainActiv, getLeaderBoard };
};

export default useLeaderboard;
