import React, { useState, useRef } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImgCrop from "antd-img-crop";
import { Image, message, Tooltip, Upload } from "antd";
import {
  faClose,
  faCaretDown,
  faCaretUp,
  faAdd,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import useImageUpload from "../../customHooks/useFirestore";
import {  useDrag, useDrop } from 'react-dnd';
import { useTrivia } from "../../contexts/TriviaContext";


const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TriviaList = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const { uploadImage, deleteImage } = useImageUpload();
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };


  const {
    triviaQuestionCache,
    updateQuestionInCache,
    updateGiveQuestionOptions,
    deleteGiveQuestionOptions,
    changeAnswere,
    deleteQuestion,
    openToggle,
    handleCustomOptionAddition,
    handleCustomQuestionImageAdd,
    handleCustomQuestionImageRemove,
    moveCard
  } = useTrivia();

  const handleChange = async ({ fileList: newFileList }, e) => {
    let users = await localStorage.getItem("logged_in");
    let parsed = await JSON.parse(users);
    if (parsed === null) {
      return message.error("You are not logged in");
    } else {
      if (newFileList.length) {
        let response = await uploadImage(
          newFileList[0].originFileObj,
          parsed?.user?.username
        );
        await handleCustomQuestionImageAdd(response, e);
      } else {
        await deleteImage(e.imageUrl);
        await handleCustomQuestionImageRemove(e);
      }
    }
  };

  const CustomOptionCard = ({ question }) => {
    const [localOptions, setLocalOptions] = useState([...question.options]); // Local state for options

    const handleOptionChange = (optionIndex, value) => {
      const updatedOptions = [...localOptions];
      updatedOptions[optionIndex] = value;
      setLocalOptions(updatedOptions); // Update local state for options
    };

    const handleOptionBlur = (optionIndex) => {
      updateGiveQuestionOptions(question.index, optionIndex, localOptions[optionIndex]); // Update global state on blur
    };

    // Handle deleting an option locally and then reflect it globally
    const handleDeleteOption = async (optionIndex) => {
      const updatedOptions = []
      await deleteGiveQuestionOptions(question.index, optionIndex)
      await localOptions.map((item, index) =>{
        if (index !== optionIndex){          
          updatedOptions.push(item)
        }
      })

      
      setLocalOptions(updatedOptions);
      // Update local state

      // Reflect deletion in the global state by updating the array
      // Pass the updated array to global state
    };
    

    return (
      <div className="flex flex-col justify-center items-end w-[100%]">
        {localOptions.length > 0 ? (
          <div className="flex flex-col  w-[100%] mt-2">
            {localOptions.map((option, i) => (
              <div key={i} className="py-2 flex flex-row self-end w-[100%]">
                <a
                  onClick={() => changeAnswere(question.index, i)}
                  className={`rounded-sm px-2 py-1 border-[#763bff] ${option === question.answer ? "bg-[#414550] text-white" : "bg-[#1F2228] text-white"}`}
                >
                  {String.fromCharCode(65 + i)}
                </a>
                <input
                  value={option}
                  onChange={(e) => handleOptionChange(i, e.target.value)} // Update local option state
                  onBlur={() => handleOptionBlur(i)} // Update global state on blur
                  className="border-0 bg-[#2E323E] rounded-md ml-2 w-[83%] px-5 py-1 text-white"
                />
                <a onClick={() => handleDeleteOption(i)}>
                  <FontAwesomeIcon color="#2E323E" style={{ paddingLeft: 10 }} icon={faClose} />
                </a>
              </div>
            ))}
            <a
              onClick={() => handleCustomOptionAddition(question)}
              className="px-5 py-2 bg-[#414550]  hover:text-white text-white text-xs w-[12%] my-5  rounded-lg cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faAdd}
                color="white"
                size="md"
              />
            </a>
          </div>
        ) : (
          <div className="w-[100%] mb-5">
            <a
              onClick={() => handleCustomOptionAddition(question)}
              className="px-5 py-2 bg-[#414550]  hover:text-white text-white text-xs  my-5 left-0 rounded-lg cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faAdd}
                color="white"
                size={"md"}
              />{" "}
              Choice
            </a>
          </div>
        )}
      </div>

    )
  }


  const CustomQuestionCard = ({ question, index }) => {
    const inputRef = useRef(null); // Ref to track the input field's focus
    const [localInputValue, setLocalInputValue] = useState(question.question); // Local state for the input

    const isInputFocused = useRef(false);

    const [, drag, dragPreview] = useDrag({
      type: 'card',
      item: { index },
      canDrag: () => !isInputFocused.current, // Disable drag when input is focused
    });

    const [, drop] = useDrop({
      accept: 'card',
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveCard(draggedItem.index, index); // Reorder when hovered
          draggedItem.index = index;
        }
      },
    });

    const handleFocus = () => {
      isInputFocused.current = true; // Disable drag when input is focused
    };

    const handleBlur = () => {
      isInputFocused.current = false; // Enable drag when input loses focus
      updateQuestionInCache(question.index, localInputValue); // Update global state when editing is done
    };

    const handleInputChange = (e) => {
      setLocalInputValue(e.target.value); // Update local input state
    };


    return (
      <div
        ref={drop}
        key={index}
        className="flex flex-col justify-center items-center my-2 w-[90%]"
      >

        <div ref={dragPreview} className="flex flex-row justify-center items-center w-[100%]">
          <Tooltip
            title={
              <span className="text-[white]">Question Number {index + 1}</span>
            }
            color="#414550"
          >
            {question.open ? (
              <a

                onClick={() => openToggle(question.index)}
                className="cursor-pointer flex flex-row items-center text-white  w-12 justify-between  mr-3 -ml-5 "
              >
                <FontAwesomeIcon
                  color="#414550"
                  style={{ paddingLeft: 10 }}
                  icon={faCaretUp}
                />
                {index + 1}
              </a>
            ) : (
              <a
                onClick={() => openToggle(question.index)}
                className="cursor-pointer flex flex-row items-center text-white  w-12 justify-between  mr-3 -ml-5 "
              >
                <FontAwesomeIcon
                  color="#414550"
                  style={{ paddingLeft: 10 }}
                  icon={faCaretDown}
                />
                {index + 1}
              </a>
            )}
          </Tooltip>
          <Tooltip
            title={
              <span className="text-[#58007C]">Click To Start Editing</span>
            }
            color="white"
          >
            <div
              // onClick={() => openToggle(question.index)}
              type="text"
              className={`border-[2px] border-[#414550] !shadow-md  w-[100%] text-left items-center rounded-[12px]   h-14 ${"!text-white"} !text-sm max-w-[100ch] overflow-hidden max-h-9`}
            >
              <div className="absolute w-5 h-5 ml-3   bg-[#2E3340] rounded-md "></div>

              <input
                ref={inputRef}
                // value={question.question}
                value={localInputValue} // Use local state for input value
                onFocus={handleFocus}
                onBlur={handleBlur}   // Update global state on blur
                onChange={handleInputChange}
                className="w-[100%] py-2 bg-[#2E3340] pl-10 outline-none z-10"
                placeholder="Click here to create question"
              />
            </div>
          </Tooltip>
          <div ref={drag} className="cursor-move ml-5">
            <FontAwesomeIcon icon={faGripVertical} size="lg" color="white" />
          </div>

          <Tooltip
            title={<span className="text-[#58007C] cursor-move">Delete Question</span>}
            color="white"
          >
            <a
              onClick={() => deleteQuestion(question.index)}
              className="cursor-pointer"
            >
              <FontAwesomeIcon
                color="#414550"
                style={{ paddingLeft: 10 }}
                icon={faClose}
              />
            </a>
          </Tooltip>
        </div>
        {question.open ? (
          <div className="w-[85%]  bg-[#24272D] p-10 mb-5 rounded-b-xl">
            <div className="">
              <div className=" ">
                {question.imageUrl == null ? (
                  <ImgCrop aspectSlider rotationSlider>
                    <Upload
                      disabled={
                        question.question || question.imageUrl != null
                          ? false
                          : true
                      }
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      // fileList={fileList}
                      onChange={(e) => handleChange(e, question)}
                      onPreview={handlePreview}
                    >
                      {question.imageUrl == null && (
                        <span>
                          {question.question ? "+ Upload" : "No Question"}
                        </span>
                      )}
                    </Upload>
                  </ImgCrop>
                ) : null}
                {question.imageUrl != null && (
                  <div className="flex flex-row justify-center items-center">
                    <Image
                      className="rounded-md"
                      width={150}
                      src={question.imageUrl}
                    />
                    <Tooltip
                      title={
                        <span className="text-[#58007C] ">
                          Change Image
                        </span>
                      }
                      color="white"
                    >
                      <a
                        onClick={() => {
                          deleteImage(question.imageUrl);
                          handleCustomQuestionImageRemove(question);
                        }}
                        className="ml-5 px-2 py-2 cursor-pointer rounded-xl text-sm text-white uppercase border-[1px]"
                      >
                        <DeleteFilled />
                      </a>
                    </Tooltip>
                  </div>
                )}
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={
                      question?.imageUrl ? question.imageUrl : previewImage
                    }
                  />
                )}
              </div>
              <CustomOptionCard question={question} />

              <a className="flex flex-row  items-center  justify-center text-center text-[#414550]">
                Correct answer:{" "}
                {!question.answer ? (
                  <span className="text-white">No Answere given</span>
                ) : (
                  <span className="text-white">{question.answer}</span>
                )}
              </a>
            </div>
          </div>
        ) : null}
      </div>

    )
  }

  React.useEffect(() => { }, [triviaQuestionCache]);

  if (triviaQuestionCache?.length > 0) {
    return (
        <div className="flex flex-col justify-center py-2 items-center  w-[100%] rounded-lg shadow-md  ">
          {triviaQuestionCache.map((question, i) => (
            <CustomQuestionCard
              key={question.index}
              question={question}
              index={i}
              moveCard={moveCard}
              openToggle={openToggle}
              updateQuestionInCache={updateQuestionInCache}
              deleteQuestion={deleteQuestion}
            />
          ))}
        </div>
    );
  } else {
    return <div>No Question Found</div>;
  }
};

export default TriviaList;




