import React, { useEffect, useState } from 'react';
import { CloseCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useGame } from '../../../contexts/GameContext';
import { useFeud } from '../../../contexts/FeudContext';
import { useTrivia } from '../../../contexts/TriviaContext';
import useTriviaQuestionCreator from '../../../customHooks/useTriviaQuestionCreator';
import event from '../../../customHooks/useGoogleAnalyticsTrigger';
import {
  getPriveate,
} from "../../common/renderCollections";
import { message } from 'antd';

const d = ['type_feud', 'type_trivia', 'access_private', 'access_public']

export default function OnEditFinish() {
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedCategory, setSelectedCategory] = useState([])
  const { triviaQuestion, triviaQuestionCache } = useTrivia()
  const { feudQuestionCache } = useFeud()
  let [newCat, setNewCat] = useState('')
  const { createQuestionWithData, updateQuestionWithData } = useTriviaQuestionCreator()
  const [categorys, _] = useState([
    "Fiction",
    "Superman",
    "Sci-Fi",
    "Education",
  ]);

  const { onPrivecyChange, title, setTitle, category, setCategory, gameTypeRef, editing, privecyTypeRef, editID, element_ID, setEditId, setElementId,  } = useGame()


  const addToList = () => {
    if (category === "") {

    } else {
      setSelectedCategory([...selectedCategory, newCat]);
    }
  };
  const selectCateogyr = (cateogry) => {
    if (selectedCategory.includes(cateogry)) {
    } else {
      setSelectedCategory((prev) => [...prev, cateogry]);
    }
  };
  const renderCategory = () => {
    console.log(category);

    if (category) {
      let temp = []
      category?.map((item, index) => {
        if (!d.includes(item)) {
          if (!item.includes('_')){
            temp.push(item)
          }
        }
      })
      setSelectedCategory(temp)
    }
  }
  const removeCategory = (name) => {
    let newSelectedCategory = selectedCategory.filter((c) => c != name);
    setSelectedCategory(newSelectedCategory);
  };

  const CreateAnewGame = async (
    title,
    selectedCategory,
    gameType,
    privacy
  ) => {
    event({
      action: "Upload",
      category: gameType,
      label: `Upload ${gameType}`,
      value: title,
    });


    let data = await createQuestionWithData(
      title,
      selectedCategory,
      gameType,
      triviaQuestionCache,
      feudQuestionCache,
      privacy
    );

    if (data == 1) {
      messageApi.success("Created");
    } else {
      messageApi.error("Failed to create");
    }
  };

  const UpdateGameQuestion = async (
    selectedCategory,
    title,
    gameType,
    privacy
  ) => {

    event({
      action: "Edit",
      category: gameType,
      label: `Edit ${gameType}`,
      value: editID,
    });

    let data = await updateQuestionWithData(
      editID,
      triviaQuestionCache,
      element_ID,
      selectedCategory,
      title,
      gameType,
      feudQuestionCache,
      privacy
    );
    if (data == 1) {
      setTitle('');
      setEditId('');
      setElementId('');
      setCategory('');
      messageApi.success("Updated");
    } else {
      setTitle('');
      setEditId('');
      setElementId('');
      setCategory('');
      messageApi.error(" Failed to Update");
    }
  };
  useEffect(() => {
    renderCategory()
  }, [])


  return (
    <div className="flex flex-col justify-center self-center items-center text-white  h-[100%]  mt-10">
      {contextHolder}
      <h1 className=' text-xl pb-5'>Extra Informations</h1>
      <div className="flex flex-col w-[100%] items-center justify-center self-center">
        <input
          value={title}
          type="text"
          className=" bg-[#2E3340] px-10 py-3 w-[50%] text-white rounded-md"
          placeholder="Add Title Here"
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="flex flex-row mt-5 border-b-[1px] pb-2 border-[#262525]">
        <input
          placeholder="Add Custom Category"
          onChange={(e) => setNewCat(e.target.value)}
          type="text"
          value={newCat}
          className="bg-[#2E3340] outline-none rounded-md text-xs py-3 px-10"
        />
        <a
          onClick={() => addToList()}
          className="bg-[#2E3340] px-2 py-2 rounded-md ml-5"
        >
          <PlusOutlined />
        </a>
        {getPriveate(onPrivecyChange)}

      </div>
      <div className="flex flex-row items-center overflow-hidden pt-5 mt-0  flex-wrap border-b-[1px] pb-5">
        <h1 className="uppercase  text-center">Added</h1>
        {selectedCategory?.map((item, index) => (
          <div
            onClick={() => removeCategory(item)}
            key={index}
            className={`${index == 0 ? 'ml-5' : null} px-5 flex flex-row mr-5 my-1 rounded-md text-[gray] py-3 bg-[#2E3340] text-sm`}
          >
            <div className="text-xs">{item}</div>
            <div className="ml-4 rounded-xl w-5 h-6  cursor-pointer text-center text-white ">
              <CloseCircleOutlined />
            </div>
          </div>
        ))}
      </div>
      <h1 className='mt-5'>Predefined Categories</h1>

      <div className="flex flex-row overflow-hidden pt-2 mt-2  flex-wrap  ">
        {categorys.map((item, index) => (
          <div
            onClick={() => selectCateogyr(item)}
            key={index}
            className="px-5 flex flex-row mr-2 my-1 rounded-md text-[gray] py-3 bg-[#2E3340] text-sm"
          >
            <div className="text-xs">{item}</div>
            <div className="ml-4 rounded-xl w-5 h-5  cursor-pointer text-center text-white ">
              <PlusOutlined />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 ">
        {editing ? (
          <button
            onClick={() =>
              UpdateGameQuestion(
                selectedCategory,
                title,
                gameTypeRef,
                privecyTypeRef.current
              )
            }
            className="border-[#6904E9] border-[1px] px-5 py-3 rounded-md uppercase ml-0 text-xs"
          >
            Edit Trivia
          </button>
        ) : (
          <button
            onClick={() =>
              CreateAnewGame(
                title,
                selectedCategory,
                gameTypeRef.current,
                privecyTypeRef.current
              )
            }
            className="bg-[#6904E9] px-5 py-3 rounded-sm uppercase text-xs"
          >
            <UploadOutlined className='mr-2' />
            Upload
          </button>
        )}
      </div>
    </div>
  );
}
