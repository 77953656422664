import React, { useEffect, useState } from 'react';
import { TikTokOutlined, TwitchOutlined, CameraOutlined, BulbOutlined, OrderedListOutlined, BgColorsOutlined } from '@ant-design/icons';
import { Tag, Flex } from 'antd';
import {
  YoutubeOutlined,
} from '@ant-design/icons';
import useLeaderboard from '../../customHooks/useLeaderboard';
import { DatePicker, Space, theme } from 'antd';
import { useNavigate } from 'react-router-dom';



export default function Dashboard() {
  const { buildLeaderFilter } = useLeaderboard()
  const navigate = useNavigate()

  const { token } = theme.useToken();
  const style = {
    border: `1px solid ${token.colorPrimary}`,
    borderRadius: '50%',
  };

  const cellRender = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div className="ant-picker-cell-inner" style={current.date() === 1 ? style : {}}>
        {current.date()}
      </div>
    );
  };

  const GameCard = ({ gradiant, name, icon, tags }) => {
    const [data, setData] = useState(null)

    let filterTagedItem = async () => {
      let tag = buildLeaderFilter.gameTag.filter((i, _) => {
        if (i.includes(tags)) { return i }
      })

      let tagedata = await buildLeaderFilter.allData.filter((item, _) => {
        if (item.s == tag[0]) {
          return item
        }
      })
      setData(tagedata)

    }


    useEffect(() => {
      filterTagedItem()
    }, [tags])

    return (
      <div onClick={() => navigate('/games/board', { state: { data } })} className={`flex flex-col cursor-pointer  justify-end  items-center py-10 shadow-md w-[30%] h-[200px] rounded-md mb-2 ${gradiant}`}>
        {icon}
        <h1 className='text-xl text-white uppercase mt-5'>{name}</h1>
        <h1 className='text-white uppercase'>found <span>{data?.length}</span></h1>
        {/* <div className="-mb-8  bg-[gray] mt-5">
          <a className='px-5 py-2 text-white bg-[#6904E9] rounded-md hover:text-white shadow-md'>Explore</a>
        </div> */}

      </div>
    )
  }

  const tagList = ['history', 'art', 'sports', 'entertainment', 'science']

  const getRandomDarkerColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    // Limit to a darker range by restricting the random values
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 8)]; // Limiting the value range to 0-7
    }

    return color;
  };

  let TagList = () => {
    const [selectedTags, setSelectedTags] = React.useState(['day', 'week']);
    const handleChange = (tag, checked) => {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
      setSelectedTags(nextSelectedTags);
    };
    return (
      <div className='mt-2 mb-5'>
        <Flex gap={4} wrap align="center">
          {buildLeaderFilter?.group?.map((item, tag) => (
            <Tag.CheckableTag

              key={item}
              checked={selectedTags.includes(item)}
              onChange={(checked) => handleChange(item, checked)}
            >
              {item}
            </Tag.CheckableTag>
          ))}
        </Flex>
      </div>
    )
  }

  const StreamOn = () => {
    return (
      <Flex className='cursor-pointer' gap="4px 0" wrap style={{ marginBottom: 10 }}>
        <Tag icon={<TikTokOutlined />} color="black">
          TikTok
        </Tag>
        <Tag icon={<YoutubeOutlined />} color="#cd201f">
          Youtube
        </Tag>
        <Tag icon={<TwitchOutlined />} color="#6904E9">
          Twitch
        </Tag>
        <Tag icon={<CameraOutlined />} color="#55acee">
          Camera
        </Tag>
      </Flex>
    )
  }

  return (
    <div className='mt-10 px-5'>
      <h1 className='text-xl relative pb-10 text-white font-extralight uppercase'>Leaderboard</h1>
      <h1 className='mb-4 text-[#797979]'>Group</h1>
      <TagList />
      <h1 className='mb-4 text-[#797979]'>Stream Platform</h1>
      <StreamOn />
      <div className='flex flex-row justify-between mb-2 items-baseline'>
        <h1 className='mb-4 text-[#797979]'>Explore our game mode</h1>
      </div>
      <div className='flex flex-row justify-between flex-wrap'>
        {[
          { name: 'Trivia', gradiant: 'bg-gradient-to-b from-indigo-500 via-purple-500 to-pink-500', icon: <BulbOutlined className='text-3xl text-white' />, tags: "trivia" },
          { name: 'Feud', gradiant: 'bg-gradient-to-b from-[#139aff] via-[#0482e1] to-[#00589b]', icon: <OrderedListOutlined className='text-3xl text-white' />, tags: 'feud' },
          { name: 'Drop', gradiant: 'bg-gradient-to-b from-[#9b6000] via-[#704806] to-[#4f3407]', icon: <BgColorsOutlined className='text-3xl text-white' />, tags: 'drop' },
        ]
          .map((item, index) => (
            <GameCard gradiant={item.gradiant} name={item.name} icon={item.icon} tags={item.tags} />
          ))}
      </div>

    </div>
  );
}



