import React, { createContext, useState, useContext } from 'react';

import { useTrivia } from './TriviaContext';
import { useFeud } from './FeudContext';
import { parseTrivia, parseTriviaDataGPT, parseTriviaData } from '../components/Main/utils';
import { mains } from '../components/constants/routes';


// Create the context
const GameContext = createContext();

// Custom hook for easy context consumption
export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const triviaID = React.useRef();
    const [editID, setEditId] = useState(null);
    const [tabActive, setTabActive] = useState('one')
    const [element_ID, setElementId] = useState("");
    const [gameType, setGameType] = useState('type_choice')

    let [textAreaInput, setTextAreaInput] = useState(null)
    let privecyTypeRef = React.useRef("access_public");
    let gameTypeRef = React.useRef("type_choice");
    let [editing, setEditing] = React.useState(false)

    // Before Save 
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')


    const changeTab = (data) => {
        setTabActive(data)
    }
    const changeGameType = (e) => {
        gameTypeRef.current = e
        setGameType(e)
    }
    const onPrivecyChange = (e) => {
        if (e){
            privecyTypeRef.current = "access_private"
        } else{
            privecyTypeRef.current = "access_public"
        }
    }

    const setConvertInput = (text) => {
        setTextAreaInput(text.target.value)
    }

    const onEditChange = async (e, editGlobalTrivaquestions, editGlobalFeaudquestions, triviaNavigate, feudNavigate) => {


        let main = await mains();
        if (e == "") {
            return;
        }

        setEditing(true);
        const url = main.API_USER_UPLOAD_QUESTION + `/${e.data.dataId}`;
        const options = {
            method: "GET",
            headers: {
                "api-key": main.API_KEY,
                Accept: "application/json",
                Authorization:
                    "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6ImUxYjkzYzY0MDE0NGI4NGJkMDViZjI5NmQ2NzI2MmI2YmM2MWE0ODciLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDU4OTA2NjYzMTcyLXFvNTA2NGwydDVvaDFoZTlwdmdzZWthMGozMHZtbTNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA1ODkwNjY2MzE3Mi1xbzUwNjRsMnQ1b2gxaGU5cHZnc2VrYTBqMzB2bW0zZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMTU1NjU2NjE2NjIxNzMzNTQzMiIsImVtYWlsIjoiZXhvMjVtZUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmJmIjoxNzE0MDQzNDAxLCJuYW1lIjoiSm9obiBYIiwicGljdHVyZSI6Imh0dHBzOi8vbGgzLmdvb2dsZXVzZXJjb250ZW50LmNvbS9hL0FDZzhvY0k1TjltZ2x6eDRYME8zalRYTTJIdDFFd2FvbVNCdXpKN3BVbWt2Ti1CMk9kanlKTnJ2PXM5Ni1jIiwiZ2l2ZW5fbmFtZSI6IkpvaG4iLCJmYW1pbHlfbmFtZSI6IlgiLCJpYXQiOjE3MTQwNDM3MDEsImV4cCI6MTcxNDA0NzMwMSwianRpIjoiNDQ0M2E4MTg1MTUxMzkyMzkyZGEzMDA3Yjk2OWIyMTE1YmQ4NzI2MyJ9.ln3FQWuIGZp8eJezPDoqXb7o6u-Hfm5431Naqi2kN3Kbk8sAJH0ph7JcvCZXzlojZ_cn0C6Lz8S07w9liLJdGQkNSIVFOp1AUy9JRsRZevmsMaWqdu9fDlNGfzb5mk71S0mPQnu9RlFyW4Ra63vyaKX6wfIHDbv7bgAMBxUjqyXKnZaNMQyrV689OqnoZHfJjos7UiPENbtDDIFd7aJqVMahpQDChVhHnMtR9uVDrfT8l7BT2ndc5Ym6D-zRATj1SSgWvLAO3LC8kkXxk63oLrLycqKJvYSPbs2qwgt8nJvMR0gs1nO4VR1P7-AMwlyv-X8ZscAw-46_NuMHss4EuA",
            },
        };

        try {
            const response = await fetch(url, options);
            const data = await response.json();     
            const games = ['type_choice', 'type_feud'];
            const vis = ['access_public', 'access_private']
            // const selectedOption = games.find(option => e?.data?.tags?.includes(option)) || 'defaultOption';

            // if (true){
            //     console.log(e, data,)
            //     console.log(data.data);
            //     console.log(selectedOption);
                
            //     return null
            // }
              
            
            setTitle(e.data.name);
            setEditId(e.data?.dataId);
            setElementId(e?.element_id);
            setCategory(e?.data?.tags);

            const selectedOption = await games.find(option => e?.data?.tags?.includes(option)) || 'type_choice';
            const visibility = await vis.find(option => e?.data?.tags?.includes(option)) || 'access_private'

            let gameVisibility = e?.data?.tags.filter((item) => {
                return item == "access_private" || item == "access_public";
            });
            gameTypeRef.current = selectedOption;
            privecyTypeRef.current = visibility
            
            if (selectedOption == "type_choice") {
                console.log("data is type_choice", data);
                
                await editGlobalTrivaquestions(data.data.questions);
                triviaNavigate()
            } else {
                console.log("data is type_fued or else", data);

                await editGlobalFeaudquestions(data.data.questions);
                feudNavigate()
            } 
            setTabActive("one");
            // setTitle('');
            // setEditId('');
            // setElementId('');
            // setCategory('');
        } catch (error) {
            console.error(error);
            // setTitle('');
            // setEditId('');
            // setElementId('');
            // setCategory('');
        }
    };







    //   // Convertor 
    //   const convert = async (text) => {

    //     // parseTriviaData
    //     let texts = null;
    //     if (gameTypeRef.current === "type_choice") {
    //       try {
    //         texts = await parseTrivia(text);

    //         let temp = [];

    //         texts.map((text, index) => {
    //           // Extract the answer letter and format the answer
    //           let answerMatch = text.answer.match(/Answer:\s([a-d])\)/i);
    //           let formattedAnswer = answerMatch
    //             ? `Answer: ${answerMatch[1]})`
    //             : text.answer;

    //           let arch = {
    //             id: index + 1,
    //             question: text.question,
    //             options: [],
    //             answer: formattedAnswer,
    //             open: false,
    //           };

    //           text.options.map((item) => {
    //             arch.options.push(item);
    //           });

    //           temp.push(arch);
    //         });

    //         // handleAdditionFromText(temp);
    //       } catch (error) {
    //         console.log(error);
    //         // info();
    //       }
    //     } else {
    //       try {
    //         texts = await parseTriviaData(text);
    //         console.log(texts);

    //         // handleAdditionFromTextFeud(texts);

    //       } catch (error) {

    //         console.log(error)
    //       }
    //     }
    //   };


    const value = {
        // Tab for input type 
        tabActive,
        changeTab,
        title,
        setTitle,

        editing,
        // Text convert
        setConvertInput,
        textAreaInput,
        title, 
        editID,
        element_ID,
        category,
        setCategory,
        onEditChange,
        setEditId,
        setElementId,

        // Game type and status
        privecyTypeRef,
        gameType,
        gameTypeRef,
        onPrivecyChange,
        changeGameType

    };

    return (
        <GameContext.Provider value={value}>
            {children}
        </GameContext.Provider>
    );
};
