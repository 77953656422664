import React from "react";
import { Radio , Button, Tooltip} from 'antd';
import { useGame } from "../../../contexts/GameContext";
import {  RollbackOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTrivia } from "../../../contexts/TriviaContext";
import { useFeud } from "../../../contexts/FeudContext";


const TabSelector = ({ firstIcon, secondIcon }) => {
    const {gameTypeRef} = useGame()
    const {resetTriviaState} = useTrivia()
    const {resetFeudState} = useFeud()
    const navigate = useNavigate()

    const onBackToMyList = async () =>{
        if (gameTypeRef.current == 'type_choice' || gameTypeRef.current == ''){
            await resetTriviaState()
            navigate('/mytrivia')
        } else{
            await resetFeudState()
            navigate('/mytrivia')
        }
    }
    const { tabActive, changeTab } = useGame()
    return (
        <div className="flex flex-row  bg-[#1D1E23]  pt-5 pl-[10%] rounded-xl mt-0 border-b-[1px]">
            <Tooltip  className="relative mr-[30%] cursor-pointer" title="Back">
                <Button className="bg-transparent border-gray-900 shadow-sm shadow-black ml-5" onClick={() =>onBackToMyList()} type="" shape="default" icon={<RollbackOutlined className="text-white" />
                } />
            </Tooltip>
            <Radio.Group
                className="border-0"
                value={tabActive}
                onChange={(e) => changeTab(e.target.value)}
                style={{
                    marginBottom: 16,
                }}
            >
                <Radio.Button className="p-2 outline-none border-0 w-[70px] mr-2 bg-[black]" value="one"> <img className="w-4 h-3" src={firstIcon} alt="First Icon" /></Radio.Button>

                <Radio.Button value="two" className="p-2 outline-none border-0 w-[70px] bg-[black]"><img className="w-4 h-3" src={secondIcon} alt="Second Icon" /></Radio.Button>
            </Radio.Group>
            <p className="text-lg text-white ml-5 uppercase font-extralight">Select Game</p>
        </div>
    );
};

export default TabSelector;
