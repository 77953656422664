import React, { useState, useRef } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImgCrop from "antd-img-crop";
import { Image, message, Spin, Tooltip, Upload } from "antd";
import {
  faClose,
  faCaretDown,
  faCaretUp,
  faAdd,
  faGripVertical
} from "@fortawesome/free-solid-svg-icons";
import { useDrag, useDrop } from "react-dnd";
import useImageUpload from "../../customHooks/useFirestore";
import { useFeud } from "../../contexts/FeudContext";

// Helper function to convert file to base64
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const TriviaListFeud = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  let [uploading, setUploading] = useState(false)

  const { uploadImage, deleteImage } = useImageUpload();
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const {
    openToggleFeud,
    updateQuestionInCacheFeud,
    updateGiveQuestionOptionFeud,
    deleteGiveQuestionOptionsFeud,
    updateFeudQuestionsOptionPoint,
    deleteQuestionFeud,
    handleCustomQuestionImageAddFeud,
    handleCustomQuestionImageRemoveFeud,
    handleCustomOptionAdditionFeud,
    moveCard,
    feudQuestionCache,
  } = useFeud();

  const handleChange = async ({ fileList: newFileList }, e) => {
    setUploading(true)
    let users = await localStorage.getItem("logged_in");
    let parsed = await JSON.parse(users);
    if (parsed === null) {
      return message.error("You are not logged in");
    } else {
      if (newFileList.length) {
        let response = await uploadImage(
          newFileList[0].originFileObj,
          parsed?.user?.username
        );
        await handleCustomQuestionImageAddFeud(response, e);
      } else {
        await deleteImage(e.imageUrl);
        await handleCustomQuestionImageRemoveFeud(e);
      }
    }
    setUploading(false)
  };

  const CustomQuestionCardFeud = ({ question, index }) => {
    const inputRef = useRef(null);
    const [localInputValue, setLocalInputValue] = useState(question.description);
    const isInputFocused = useRef(false);

    // useDrag and useDrop hooks from react-dnd
    const [, drag, dragPreview] = useDrag({
      type: "card",
      item: { index },
      canDrag: () => !isInputFocused.current, // Disable drag when input is focused
    });

    const [, drop] = useDrop({
      accept: "card",
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveCard(draggedItem.index, index); // Reorder when hovered
          draggedItem.index = index;
        }
      },
    });

    const handleFocus = () => {
      isInputFocused.current = true; // Disable drag when input is focused
    };

    const handleBlur = () => {
      isInputFocused.current = false; // Enable drag when input loses focus
      updateQuestionInCacheFeud(question.index, localInputValue); // Update global state when editing is done
    };

    const handleInputChange = (e) => {
      setLocalInputValue(e.target.value); // Update local input state
    };

    return (
      <div ref={drop} key={index} className="flex flex-col justify-center items-center my-2 w-[90%]">
        <div ref={dragPreview} className="flex flex-row justify-center items-center w-[100%]">
          <Tooltip
            title={<span className="text-[white]">Question Number {index + 1}</span>}
            color="#414550"
          >
            {question.open ? (
              <a
                onClick={() => openToggleFeud(question.index)}
                className="cursor-pointer flex flex-row items-center text-white w-12 justify-between mr-3 -ml-5"
              >
                <FontAwesomeIcon color="#414550" style={{ paddingLeft: 10 }} icon={faCaretUp} />
                {index + 1}
              </a>
            ) : (
              <a
                onClick={() => openToggleFeud(question.index)}
                className="cursor-pointer flex flex-row items-center text-white w-12 justify-between mr-3 -ml-5"
              >
                <FontAwesomeIcon color="#414550" style={{ paddingLeft: 10 }} icon={faCaretDown} />
                {index + 1}
              </a>
            )}
          </Tooltip>
          <Tooltip title={<span className="text-[#58007C]">Click To Start Editing</span>} color="white">
            <div
              type="text"
              className={`border-[2px] border-[#414550] !shadow-md w-[100%] text-left items-center rounded-[12px] h-14 !text-white !text-sm max-w-[100ch] overflow-hidden max-h-9`}
            >
              <div className="absolute w-5 h-5 ml-3 bg-[#2E3340] rounded-md"></div>
              <input
                ref={inputRef}
                value={localInputValue}
                onFocus={handleFocus}
                onBlur={handleBlur} // Update global state on blur
                onChange={handleInputChange}
                className="w-[100%] py-2 bg-[#2E3340] pl-10 outline-none z-10"
                placeholder="Click here to create feud question"
              />
            </div>
          </Tooltip>
          <div ref={drag} className="cursor-move ml-5">
            <FontAwesomeIcon icon={faGripVertical} size="lg" color="white" />
          </div>
          <Tooltip title={<span className="text-[#58007C]">Delete Question</span>} color="white">
            <a onClick={() => deleteQuestionFeud(question.index)} className="cursor-pointer">
              <FontAwesomeIcon color="#414550" style={{ paddingLeft: 10 }} icon={faClose} />
            </a>
          </Tooltip>
        </div>
        {question.open ? (
          <div className="w-[80%] ml-  bg-[#24272D] p-10 mb-5 rounded-b-xl">
            <div className="">
              <div className=" ">
                {question.imageUrl == "" ? (
                  <ImgCrop aspectSlider rotationSlider>
                    <Upload
                      disabled={
                        !uploading
                          ? false
                          : true
                      }
                      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      // fileList={fileList}
                      onChange={(e) => handleChange(e, question)}
                      onPreview={handlePreview}
                    >
                      {question.imageUrl == "" && (
                        <span>
                          {!uploading
                            ? " + Upload"
                            : "Uploading"}
                        </span>
                      )}
                      {uploading && <Spin />}

                    </Upload>
                  </ImgCrop>
                ) : null}
                {question.imageUrl != "" && (
                  <div className="flex flex-row justify-center items-center">
                    <Image
                      className="rounded-md"
                      width={150}
                      src={question.imageUrl}
                    />
                    <Tooltip
                      title={
                        <span className="text-[#58007C] ">
                          Change Image
                        </span>
                      }
                      color="white"
                    >
                      <a
                        onClick={() => {
                          deleteImage(question.imageUrl);
                          handleCustomQuestionImageRemoveFeud(question);
                        }}
                        className="ml-5 px-2 py-2 cursor-pointer rounded-xl text-sm text-white uppercase border-[1px]"
                      >
                        <DeleteFilled />
                      </a>
                    </Tooltip>
                  </div>
                )}
                {previewImage && (
                  <Image
                    wrapperStyle={{
                      display: "none",
                    }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={
                      question?.imageUrl != ""
                        ? question.imageUrl
                        : previewImage
                    }
                  />
                )}
              </div>
              <div className="flex flex-col justify-center items-end w-[100%]">
                {question.answerPoints.length > 0 ? (
                  <div className="flex flex-col  w-[100%] mt-2">
                    {question.answerPoints.map((select, i) => {
                      return (
                        <div
                          key={i}
                          className="py-2 flex flex-row self-end w-[100%]"
                        >
                          <div className="flex flex-row w-[100%]">
                            <input
                              value={select?.key}
                              onChange={(e) =>
                                updateGiveQuestionOptionFeud(
                                  question.index,
                                  i,
                                  e.target.value
                                )
                              }
                              placeholder={`Answer ${i + 1}`}
                              className=" border-0 bg-[#2E323E] rounded-md ml-2 w-[100%] px-5 py-1 text-white"
                            />
                            <input
                              type="number"
                              value={select?.value}
                              onChange={(e) =>
                                updateFeudQuestionsOptionPoint(
                                  question.index,
                                  i,
                                  e.target.value
                                )
                              }
                              placeholder="Point"
                              className=" border-0 bg-[#2E323E] rounded-md ml-2 w-[20%] px-5 py-1 text-white"
                            />
                          </div>
                          <a
                            onClick={() =>
                              deleteGiveQuestionOptionsFeud(
                                question.index,
                                i
                              )
                            }
                          >
                            <FontAwesomeIcon
                              color="#2E323E"
                              style={{ paddingLeft: 10 }}
                              icon={faClose}
                            />
                          </a>
                        </div>
                      );

                    })}
                    <a
                      onClick={() => handleCustomOptionAdditionFeud(question)}
                      className="px-5 py-2 bg-[#414550]  hover:text-white text-white text-xs w-[12%] my-5  rounded-lg cursor-pointer"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="white"
                        size="md"
                      />
                    </a>
                  </div>
                ) : (
                  <div className="w-[100%] mb-5">
                    <a
                      onClick={() => handleCustomOptionAdditionFeud(question)}
                      className="px-5 py-2 bg-[#414550]  hover:text-white text-white text-xs  my-5 left-0 rounded-lg cursor-pointer"
                    >
                      <FontAwesomeIcon
                        icon={faAdd}
                        color="white"
                        size={"md"}
                      />{" "}
                      Choice
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  React.useEffect(() => { }, [feudQuestionCache]);

  if (feudQuestionCache.length > 0) {
    return (
      <div className="flex flex-col justify-center py-2 items-center w-[100%] rounded-lg shadow-md">
        {feudQuestionCache.map((question, i) => (
          <CustomQuestionCardFeud
            key={question.index}
            question={question}
            index={i}
          />
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default TriviaListFeud;
