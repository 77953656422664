import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { TriviaProvider } from './contexts/TriviaContext';
import { FeudProvider } from './contexts/FeudContext';
import { GameProvider } from './contexts/GameContext'



// Find the root element in your HTML
const rootElement = document.getElementById('root');
// Create a root
const root = createRoot(rootElement);


// Use the root to render your app component wrapped in BrowserRouter
root.render(
  <React.StrictMode>
    <GameProvider>
      <FeudProvider>
        <TriviaProvider>
          <Router>
            <App />
          </Router>
        </TriviaProvider>
      </FeudProvider>
    </GameProvider>
  </React.StrictMode>
);
