import { useState } from 'react';
import { message } from "antd";
import { mains } from '../components/constants/routes';
import { useNavigate } from "react-router-dom";




function formatCategoryName(categoryName) {
  // Replace spaces with hyphens
  let formattedName = categoryName.replace(/\s+/g, '-');

  // Convert to lowercase
  formattedName = formattedName.toLowerCase();

  // Append 'display_' to the beginning
  formattedName = 'display_' + formattedName;

  return formattedName;
}


function getCurrentDateTime() {
  const now = new Date();
  const month = now.getMonth() + 1; // getMonth() is zero-indexed
  const day = now.getDate();
  const year = now.getFullYear();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 24h to 12h format and handle midnight case
  return `${month}/${day}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;
}




function parsefeudQuestionCache(feudQuestionCache) {
  let df = 300
  let newData = []
  feudQuestionCache.map((item, index) => {
    if (item.description != null) {
      let feudQueston = {

      }
      let temp = {
        "description": item.description,
        "answers": null,
        "answerPoints": feudQueston,
        "imageUrl": item.imageUrl,
      }
      item.answerPoints.map((item, index) => {
        feudQueston[item.key] = item.value || parseInt(item.value) === 0 ? parseInt(item.value) : parseInt(df)
      })

      newData.push(temp)
    }
  })
  return newData
}

function generateUniqueId(prefix = "trivia-questions") {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${prefix}-${s4()}-${s4()}-${s4()}-${s4()}`;
}
let Tagset = ["type_choice", "type_feud", 'access_public', 'access_private'];

const useTriviaQuestionCreator = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [customTrivia, setCustomTrivia] = useState({});
  const navigate = useNavigate();
  /**
   lookup field need to be updated to reflex some changes 
  */


  const uploadLookupToQuestion = async (qustionToUpload) => {
    let main = await mains()
    console.log(qustionToUpload);
    

    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_ELEMENT_GAME_DATA, options);
      const data = await response.json();
      console.log('logged',data);
      
      
      if (data?.success) {
        messageApi.success("Done");
      } else {
        messageApi.info(`${data.message} `);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createQuestionWithData = async (title, selectedCategory, gameType, triviaQuestion, feudQuestionCache, privecy) => {
    console.log("testing", feudQuestionCache, gameType);

    let main = await mains()
    let user = await localStorage.getItem("logged_in");
    if (user == null) {
      navigate("/login");
    }
    let jsonUser = await JSON.parse(user);
    let auther = jsonUser?.user?.username;
    // Created Date Time
    let now = await getCurrentDateTime();
    // Firste Create a trivia id for the user
    let newGeneratedID = await generateUniqueId();

    // Then Build the specific Data for upload to update the list
    let listToUpdated = {
      name: title,
      author: auther,
      dataId: newGeneratedID,
      created: now,
      tags: [...selectedCategory, gameType, privecy, formatCategoryName(title), `user_${jsonUser?.user.id}`],
    };
    // // uppend it to the old trivia list
    let uploadableJson = {
      data_id: "trivia-question-list",
      data: listToUpdated,
      tags: [...selectedCategory, gameType, privecy, formatCategoryName(title), `user_${jsonUser?.user?.id}`],
      element_id: newGeneratedID,
      sort_field_1: "000000000000"
    };
    let triviaID = newGeneratedID;
    // upload the new json file to the server

    await uploadLookupToQuestion(uploadableJson);
    // Once the user data is set Lock the Button and move on
    // Now Get the questions and upload them to the question server
    let qustionToUpload = {
      data_id: triviaID,
      type: gameType,
      data: {
        questions: gameType == 'type_choice' || '' ? triviaQuestion : parsefeudQuestionCache(feudQuestionCache),
      },
      tags: uploadableJson.tags,
      element_id: "0",
    };

    console.log("question to upload", qustionToUpload);

    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_UPLOAD_QUESTION, options);
      const data = await response.json();
      if (data?.success) {
        // alert("Success")
        return 1
      } else {
        // alert("Error")
        return 0
      }
    } catch (error) {
      console.error(error);
    }
  };


  
  // Update Feud and Trivia

  const updateQuestionWithData = async (editID, triviaQuestionCache, elementID, selectedCategory, title, gameType, feudQuestionCache, privecy) => {
    let main = await mains()
    let user = await localStorage.getItem("logged_in");
    if (user == null) {
      navigate("/login");
    }

    console.log(title);
    

    let jsonUser = await JSON.parse(user);
    let auther = jsonUser?.user?.username;
    // // Created Date Time
    let now = await getCurrentDateTime();
    let tags = [];
    selectedCategory.map((item, index) => {
      if (!Tagset.includes(item)) {
        tags.push(item);
      }
    });
    let listToUpdated = {
      name: title,
      author: auther,
      dataId: editID,
      created: now,
      tags: [...tags, gameType.current, privecy],
    };
    console.log(listToUpdated);
    

    // // ---------------------------------------------------------------- would be ideal if we check for redundant titles
    // // Create the new json file to upload
    let uploadableJson = {
      data_id: "trivia-question-list",
      data: listToUpdated,
      tags: [...selectedCategory, gameType, privecy],
      element_id: editID,
    };
    await uploadLookupToQuestion(uploadableJson);

    let qustionToUpload = {
      data_id: editID,
      data: {
        questions: gameType.current == 'type_choice' || '' ? triviaQuestionCache : parsefeudQuestionCache(feudQuestionCache),
      },
    };

    // if (true) {
    //   console.log("\x1b[31m editID", editID);
    //   console.log("\x1b[32m trivia", triviaQuestionCache);
    //   console.log("\x1b[34mfeud", feudQuestionCache);
    //   console.log("\x1b[37mgameType", gameType.current);
    //   console.log("\x1b[37mgameType", privecy);
    //   console.log("\x1b[32m to upload", qustionToUpload);
      
    //   return null

    // }


    const options = {
      method: "POST",
      headers: {
        "api-key": main.API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(qustionToUpload),
    };

    try {
      const response = await fetch(main.API_USER_UPLOAD_QUESTION, options);
      const data = await response.json();
      if (data?.success) {
        return 1
      } else {
        return 0
      }
    } catch (error) {
      console.error(error);
    }
  };
  return { createQuestionWithData, updateQuestionWithData };
};

export default useTriviaQuestionCreator;


