import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom"; // Import Routes and Route components
import "./App.css";
import Main from "./components/Main";
import "./components/PromptInput/PromptInput.css";
import Auth from "./components/Auth";
import MyTrivia from "./components/MyTrivia";
import LayoutMain from "./components/Layout";
import Dashboard from "./components/Dashboard";
import Settings from "./pages/settings";
import ProtectedRoute from "./components/protectedRoute/index.jsx";
import AccountOverview from "./pages/accountOverview.jsx";
import PopularGames from "./pages/PopularGames.jsx";
import LeaderBoard from "./pages/LeaderBoard.jsx";
import Games from './pages/Games.jsx'
import Filter from "./components/Dashboard/FilteredComponent.jsx";
import { Button } from "antd";
import { BackwardFilled, HomeFilled } from "@ant-design/icons";

const NotFound = () => {
  return (
      <div className="flex flex-col justify-center w-full items-center h-[100vh] bg-slate-800">
          <h1 className="text-xl text-white font-bold">404 - Page Not Found</h1>
          <p className="text-md text-gray-300">Sorry, the page you are looking for does not exist.</p>
          <Button onClick={() => window.history.back()} className="mt-5 border-none !text-white bg-[#6904E9] hover:!bg-[#6904E9] flex flex-row justify-center items-center"><HomeFilled />Go Back Home</Button>
      </div>
  );
};


function App() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [user, setUser] = useState(null);
  const authRef = React.useRef(null);
  const [loading, setIsLoading] = useState(true);

  // Authentication check function
  const authenticated = async () => {
    let users = localStorage.getItem("logged_in");
    if (users) {
      let parsed = JSON.parse(users);
      authRef.current = parsed;
      setUser(parsed);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    authenticated();
  }, [user?.identity_token]);

  // Navbar visibility on scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos > currentScrollPos) {
        document.getElementById("header").style.top = "0"; // Show navbar when scrolling up
      } else {
        document.getElementById("header").style.top = "-13vh"; // Hide navbar when scrolling down
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  // If still loading, return null or a loader here
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      {/* Public Route */}
      <Route path="/login" element={<Auth />} />

      {/* Protected Routes wrapped in ProtectedRoute */}
      <Route path="/" element={<LayoutMain />}>
        {/* Protecting individual routes */}
        <Route
          index
          path="/"
          element={<ProtectedRoute element={Dashboard} />}
        />
        <Route
          path="/account-overview"
          element={<ProtectedRoute element={AccountOverview} />}
        />
        <Route path="/gamelist" element={<ProtectedRoute element={Main} />} />
        <Route
          path="/mytrivia"
          element={<ProtectedRoute element={MyTrivia} />}
        />
        <Route
          path="/settings"
          element={<ProtectedRoute element={Settings} />}
        />
        <Route
          path="/games/list"
          element={<ProtectedRoute element={Games} />} />


        <Route path="/data">
          <Route
            path="/data/found"
            element={<ProtectedRoute element={Filter} />}
          />
        </Route>
        <Route path="/games">
          <Route
            path="/games/popular"
            element={<ProtectedRoute element={PopularGames} />}
          />
          <Route
            path="/games/board"
            element={<ProtectedRoute element={LeaderBoard} />}
          />
          <Route
            path="/games/conf"
            element={<ProtectedRoute element={Settings} />}
          />
        </Route>

      </Route>
      <Route path="*" element={<NotFound />} />

    </Routes>
  );
}

export default App;
