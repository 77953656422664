import { Link, Outlet } from 'react-router-dom';
import React, { useState } from 'react';
import {
    DesktopOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    DashboardOutlined,
    SettingOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, ConfigProvider } from 'antd';
import Header from '../Header/Header';
import logoPath from "../../assets/images/beemi-logo.png";

const { Content, Footer, Sider } = Layout;

export default function LayoutMain() {

    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }
    const items = [
        // getItem(<Link to={'/'} className=' active:text-white !text-[white] font-extralight uppercase'>Dashboard</Link>, '1', <DashboardOutlined style={{ color: 'white', fontSize: 20 }} />),
        // getItem(<Link to={'/account-overview'} className=' active:text-white !text-[white] font-extralight uppercase'>Account</Link>, '2', <DesktopOutlined style={{ color: 'white', fontSize: 20 }} />),
        // getItem(<p className=' active:text-white !text-[white] font-extralight uppercase'>Manage Data</p>, 'sub1', <UserOutlined style={{ color: 'white', fontSize: 20 }} />, [
        // getItem(<Link to={'/mytrivia'} className=' active:text-white !text-[white] font-extralight uppercase'>My List</Link>, '6')
        // ]),
        // getItem(
        //     <Link to={'/'} className=' active:text-white !text-[white] font-extralight uppercase'>Games</Link>, 'sub2', <TeamOutlined style={{ color: 'white', fontSize: 20 }} />,
        //     [
        //         getItem(<Link to={'/gamelist'} className=' active:text-white !text-[white] font-extralight uppercase'>Trivia</Link>, '3'), [
        //             getItem(<Link to={'/games/popular'} className=' active:text-white !text-[white] font-extralight uppercase'>Popular Games</Link>, '4'),
        //             getItem(<Link to={'/games/board'} className=' active:text-white !text-[white] font-extralight uppercase'>Leader Board</Link>, '5'),
        //         ]

        //     ]),
        // getItem(<Link to={'/settings'} className=' active:text-white !text-[white] font-extralight uppercase'>Settings</Link>, '9', <SettingOutlined style={{ color: 'white', fontSize: 20 }} />),
        // getItem(<Link to={'/'} className=' active:text-white !text-[white] font-extralight uppercase'>Support & Help</Link>, '10', <PieChartOutlined style={{ color: 'white', fontSize: 20 }} />)

        getItem(
            <Link to={'/'} className="active:text-white !text-[white] font-extralight uppercase">Games</Link>,
            'sub2',
            <TeamOutlined style={{ color: 'white', fontSize: 20 }} />,
            [
                getItem(<Link to={'/'} className="active:text-white !text-[white] font-extralight uppercase">Trivia</Link>, '3',   null,                  [
                    getItem(<Link to={'/mytrivia'} className=' active:text-white !text-[white] font-extralight uppercase'>My List</Link>, '6'),
                    getItem(<Link to={'/games/board'} className="active:text-white !text-[white] font-extralight uppercase">Leaderboard</Link>, '5'),
                    getItem(<Link to={'/games/conf'} className="active:text-white !text-[white] font-extralight uppercase">Config</Link>)
                ]),
            ]
        ),
        // getItem(
        //     <Link to={'/settings'} className="active:text-white !text-[white] font-extralight uppercase">Settings</Link>,
        //     '9',
        //     <SettingOutlined style={{ color: 'white', fontSize: 20 }} />
        // ),
        getItem(
            <Link to={'/account-overview'} className="active:text-white !text-[white] font-extralight uppercase">Settings</Link>,
            '10',
            <SettingOutlined style={{ color: 'white', fontSize: 20 }} />
        )
    ];


    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();



    return (
        <ConfigProvider
            theme={{
                colorPrimary: '#1D1E23',
                components: {
                    Menu: {
                        itemActiveBg: 'black',
                        itemHoverBg: '#6904E9',
                        itemSelectedBg: 'black',
                    },
                    Sider: {
                        colorPrimary: '#1D1E23',

                    },
                    Select: {
                        colorPrimary: '#6904E9',
                        colorText: '#6904E9',
                        colorBorder: '#6904E9',
                        colorBgContainer: '#1d1e23',
                    },
                    Table: {
                        colorPrimary: '#6904E9',
                        colorText: 'white',
                        colorBorder: '#6904E9',
                        colorBgContainer: '#1d1e23',
                        borderColor: '#1D1E23'
                    },
                    List: {
                        colorText: 'white',
                        borderColor: '#1d1e23'
                    },
                    DatePicker: {
           
                    },
                    RangePicker:{
                        backgroundColor: 'white'
                    }
                },

                token: {
                    // Seed Token
                    colorPrimary: '#6904E9',
                    borderRadius: 2,

                    // Alias Token
                    colorBgContainer: '#6904E9',

                },
            }}
        > <Layout
            style={{
                minHeight: '100vh',
            }}
        >
                <Sider style={{
                    padding: 0,
                    background: '#1D1E23',

                }} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                    <a href="/" className={`flex flex-row justify-center items-center border-b-[0px] pb-1 ${collapsed ? 'mt-5' : 'm-5'}`}>
                        <img src={logoPath} alt="Beemi Trivia Logo" className='w-[126px]' />
                    </a>

                    <Menu style={{ background: '#1D1E23', color: 'white', paddingTop: 20, }} defaultSelectedKeys={['1']} mode="inline" items={items} />
                </Sider>
                <Layout style={{ backgroundColor: '#2b2e38' }}>
                    <Header />
                    <Content
                        style={{
                            margin: '16px 16px',
                            background: '#1D1E23',
                            borderRadius: 20
                        }}
                    >
                        <Outlet />
                    </Content>
                    <Footer
                        style={{
                            textAlign: 'center',
                            background: '#2b2e38',
                            color: 'white'
                        }}
                    >
                        Kinet Live ©{new Date().getFullYear()}
                    </Footer>
                </Layout>
            </Layout>
        </ConfigProvider>

    );
}
