import React, { useState, useEffect } from "react";

const UserInfo = ({ user }) => (
  <section className="rounded-lg p-6 mb-8 shadow-sm shadow-black">
    <h2 className="text-sm font-extralight uppercase mb-4 text-white">User Information</h2>
    <div className="flex items-center space-x-6">
      <img 
        src={user.profile_pic_url || "https://via.placeholder.com/150"} 
        alt="Profile" 
        className="w-24 h-24 rounded-full"
      />
      <div>
        <p className="text-sm"><span className="font-light text-gray-400 uppercase">Email:</span> <span className="uppercase ml-5">{user.email}</span></p>
        <p className="text-sm"><span className="font-light text-gray-400 uppercase">Username:</span> <span className="uppercase ml-5">{user.username}</span></p>
        <p className="text-sm"><span className="font-light text-gray-400 uppercase">Display Name:</span> <span className="uppercase ml-5">{user.display_name}</span></p>
      </div>
    </div>
  </section>
);

const BillingPlanInfo = ({ onViewHistory, onChangePlan }) => (
  <section className="rounded-lg p-6 mb-8 shadow-sm shadow-black">
    <h2 className="text-sm font-extralight uppercase mb-4 text-white">Billing Plan Information</h2>
    <p className="text-sm mb-4"><span className="font-medium text-gray-400">Current Plan:</span> Premium</p>
    <div className="flex space-x-4">
      <button 
        className="border-[1px] text-white font-light py-2 px-4 rounded transition duration-300"
        onClick={onViewHistory}
      >
        View Billing History
      </button>
      <button 
        className="border-[1px] hover:bg-[#4A7CE2] text-white font-light py-2 px-4 rounded transition duration-300"
        onClick={onChangePlan}
      >
        Change Plan
      </button>
    </div>
  </section>
);

const PlanSelection = () => (
  <section className="rounded-lg p-6 mb-8 shadow-sm shadow-black">
    <h2 className="text-sm font-extralight uppercase mb-4 text-white">Available Plans</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {['Free', 'Premium', 'Enterprise'].map((plan, index) => (
        <div key={plan} className="bg-[#2c2d31] border-[black] border-[1px] p-6 rounded-lg text-center transform transition duration-300 hover:scale-105">
          <h3 className="text-xl font-extralight mb-2">{plan}</h3>
          <p className="text-2xl font-extralight mb-4">
            {index === 0 ? '0' : index === 1 ? '599.99' : 'Custom'}<span className="text-sm font-normal"> Birr/month</span>
          </p>
          <button className="w-full border-[black] border-[1px] hover:bg-[#6904E9] text-white font-extralight py-2 px-4 rounded transition duration-300">
            {index === 2 ? 'Contact Us' : 'Select'}
          </button>
        </div>
      ))}
    </div>
  </section>
);

const BillingHistoryPopup = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-[#1D1E23] p-6 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
      <h2 className="text-sm font-extralight uppercase mb-4 text-white">Billing History</h2>
      <table className="w-full text-left">
        <thead>
          <tr className="border-b border-gray-700">
            <th className="py-2 px-4 font-semibold">Date</th>
            <th className="py-2 px-4 font-semibold">Description</th>
            <th className="py-2 px-4 font-semibold">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b border-gray-700">
            <td className="py-2 px-4">2023-05-01</td>
            <td className="py-2 px-4">Premium Plan - Monthly</td>
            <td className="py-2 px-4">599.99 Birr</td>
          </tr>
          <tr>
            <td className="py-2 px-4">2023-04-01</td>
            <td className="py-2 px-4">Premium Plan - Monthly</td>
            <td className="py-2 px-4">599.99 Birr</td>
          </tr>
        </tbody>
      </table>
      <button 
        className="mt-4 border-[#6904E9] border-[1px] hover:bg-[#6904E9] text-white font-bold py-2 px-4 rounded transition duration-300"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
);

const PlanSelectionPopup = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-[#1D1E23] p-6 rounded-lg max-w-4xl w-full">
      <h2 className="text-sm font-extralight uppercase mb-4 text-white">Select a Plan</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {['Free', 'Premium', 'Enterprise'].map((plan, index) => (
          <div key={plan} className="bg-[#201f1f] p-6 rounded-lg text-center border-[1px]">
            <h3 className="text-xl font-extralight mb-2">{plan}</h3>
            <p className="text-1xl font-extralight mb-4">
              {index === 0 ? '0' : index === 1 ? '599.99' : 'Custom'}<span className="text-sm font-normal"> Birr/month</span>
            </p>
            <button className="w-full border-[1px] border-[black] hover:bg-[#6904E9] text-white font-extralight py-2 px-4 rounded transition duration-300">
              {index === 2 ? 'Contact Us' : 'Select'}
            </button>
          </div>
        ))}
      </div>
      <button 
        className="mt-6 bg-[#6904E9] hover:bg-[#6904E9] text-white font-extralight py-2 px-4 rounded transition duration-300"
        onClick={onClose}
      >
        Close
      </button>
    </div>
  </div>
);

const AccountOverview = () => {
  const [userData, setUserData] = useState(null);
  const [showBillingHistory, setShowBillingHistory] = useState(false);
  const [showPlanSelection, setShowPlanSelection] = useState(false);

  useEffect(() => {
    const fetchUserData = () => {
      let storedData = localStorage.getItem("logged_in");
      if (storedData) {
        let parsedData = JSON.parse(storedData);
        setUserData(parsedData);
      }
    };
    fetchUserData();
  }, []);

  if (!userData || !userData.user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col bg-[#1D1E23] text-gray-200 min-h-auto w-[100%] p-8 rounded-xl">
      <div className="">
        <h1 className="text-xl font-extralight uppercase mb-8 text-white">Account Overview</h1>
        <UserInfo user={userData.user} />
        {/* <BillingPlanInfo 
          onViewHistory={() => setShowBillingHistory(true)}
          onChangePlan={() => setShowPlanSelection(true)}
        />
        <PlanSelection /> */}
      </div>

      {/* {showBillingHistory && <BillingHistoryPopup onClose={() => setShowBillingHistory(false)} />}
      {showPlanSelection && <PlanSelectionPopup onClose={() => setShowPlanSelection(false)} />} */}
    </div>
  );
};

export default AccountOverview;
