import React, { useRef, useState, useEffect } from 'react';
import { Avatar, List, Skeleton, Spin, Tag } from 'antd';
import moment from 'moment'
import { Input, Typography, Button } from 'antd';
import useLeaderboard from '../customHooks/useLeaderboard';
import { useLocation, useNavigate } from 'react-router-dom';
import { EyeFilled, RollbackOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Text } = Typography;


const Games = () => {
    const [initLoading, setInitLoading] = useState(true);
    const [timer, setTimer] = useState({
        start: null,
        end: null
    })
    const location = useLocation()
    const { getMainActiv } = useLeaderboard()
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    let [id, setId] = useState(location?.state?.id)
    const navigate = useNavigate()


    const getData = async () => {
        console.log('id', id);
        let temp = await getMainActiv(id)
        console.log("temp data", temp);

        setData(temp?.data?.leaderboard)
    }

    useEffect(() => {
        if (location) {
            setId(location.state.id)
            setTimer({
                start: location.state.start,
                end: location.state.end
            })
            setLoading(false)
            console.log('params', location.state);

        }
    }, [location])


    useEffect(() => {
        // if(id){
        getData()
        // }
    }, [loading])

    if (loading) return <Spin />

    return (
        <div className='w-[85%] !mx-20'>
            <div className="flex flex-row justify-between items-center w-[100%] mb-0 pb-5 border-b-[gray] border-b-[0px] mt-10">
                <div className="flex flex-row items-center">
                    <a className="mr-5 shadow-sm shadow-black p-1" onClick={() => navigate(-1)}><RollbackOutlined className="text-white text-lg mr-2 hover:text-[#4362a1]" /></a>
                    <h1 className="text-white text-xl font-medium text-left ">Stream</h1>
                </div>
                <Button onClick={() => navigate('/gamelist')} className="text-white border-0 hover:text-white">Start</Button>
            </div>
            <div className='flex flex-row justify-between pt-5 pb-5 border-b-[1px] border-[#2d2d2d]'>
                <div className='text-[#c7c0c0] uppercase'>Started At <span className='capitalize text-white'>{moment(timer.start).format('ddd MMM YYYY')}</span></div>
                <div className='text-[#c7c0c0] uppercase'>Ended At <span className='capitalize text-white'>{moment(timer.end).format('ddd MMM YYYY')}</span></div>
            </div>
            <List
                loading={loading}
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[<a href={`https://www.youtube.com/channel/${item.id}`} target='__blank' className='hover:text-[#9a77c8] uppercase text-[#808480]' key="list-loadmore-edit">View Channel</a>]}
                    >
                        <Skeleton avatar title={false} loading={loading} active>
                            <List.Item.Meta
                                className=''
                                avatar={<Avatar src={item?.snippet?.thumbnails?.default?.url} className='mr-2' size={40} />}
                                title={null}
                                description={
                                    <div className='flex flex-col'>
                                        <span className='text-white'>{item.snippet.title}</span>
                                        <p className='text-[#a5a4a4]'>{item.id}</p>
                                        <Tag className='uppercase text-white w-20 bg-[black] text-center text-xs mt-2 border-0 rounded-lg'><EyeFilled className='mx-2' />{item.s}</Tag>
                                    </div>}
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />
        </div>
    );


};
export default Games;

// <div className='flex flex-row justify-between items-center flex-wrap w-[100%] px-[2%] '>
//   {datas.map((item, index) => {
//     let co = getRandomDarkerColor()
//     return (
//       <Tag icon={resolveIcon(item)} className='w-[250px]  m-3 rounded-md' color={co}>
//         <div className='flex flex-col justify-center items-center'>
//           {/* <a>username {item.uU}</a> */}
//           <a><Avatar src={item.u} className='mr-2' />{item.n}</a>
//           <div className='flex flex-row'>
//             <EyeFilled />
//             <h1 className='ml-2'>{item.s}</h1>
//           </div>
//         </div>
//       </Tag>
//     )
//   })}
// </div>