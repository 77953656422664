import React from "react";
import "./Header.css"; // Make sure to import the corresponding CSS file
import { Avatar } from "antd";
import { LogoutOutlined, DeliveredProcedureOutlined } from "@ant-design/icons";
import { Dropdown, Radio } from "antd";

// Update the logoPath to the actual path where your logo is stored.
import logoPath from "../../assets/images/beemi-logo.png";

const Header = () => {
  const [auth, setAuth] = React.useState(null);
  // let env = React.useRef("production");
  // let [envValue, setEnvValue] = React.useState('production');
  // const users = ['exo25me@gmail.com', 'tesfaye.exodus@qenetech.com']
  const authRef = React.useRef(null);
  const authenticated = async () => {
    let user = await localStorage.getItem("logged_in");
    let parsed = await JSON.parse(user);
    authRef.current = parsed;
    console.log(parsed)
    setAuth(parsed);
  };
  const logout = async (key) => {
    await localStorage.removeItem("logged_in");
    await localStorage.removeItem("cred");
    await localStorage.removeItem("clientId");
  };

  // const changeEnv = async(e) =>{
  //   console.log(e.target.value)
  //   await localStorage.setItem("evn", e.target.value);
  //   env.current = e.target.value;
  //   setEnvValue(e.target.value)
  // }
  const items = [
    {
      key: "1",
      label: <a href="/mytrivia">My Trivia</a>,
    },
    {
      key: "2",
      label: (
        <a href="/" target="_blank">
          Setting
        </a>
      ),
      icon: <DeliveredProcedureOutlined />,
    },
    {
      key: "3",
      label: (
        <a onClick={() => logout()} href="/login" rel="noopener noreferrer">
          logout
        </a>
      ),
    },
  ];

  React.useEffect(() => {
    authenticated();
  }, []);

  return (
    <header id="header" className="header shadow-xl  z-10">
      <a href="/" className="cursor-pointer">
        {/* <img src={logoPath} alt="Beemi Trivia Logo" className="header-logo" /> */}
      </a>
      {!auth ? (
        <>
          <a
            href="/login"
            className="bg-[#6904E9] px-5  text-center text-white py-1 rounded-md hover:shadow-md "
          >
            <span className="text-white text-sm">Sign In</span>
          </a>
        </>
      ) : (
        <>
          <Dropdown
            menu={{
              items,
            }}
          >
            <Avatar style={{borderWidth: 1, borderColor: 'white', backgroundColor: 'gray'}} size={30} src={authRef.current?.user?.profile_pic_url} />
          </Dropdown>
        </>
      )}
    </header>
  );
};

export default Header;
