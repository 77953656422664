import React, { useEffect, useState } from 'react';
import useLeaderboard from '../customHooks/useLeaderboard';
import { ExperimentFilled, TikTokFilled, YoutubeFilled } from '@ant-design/icons';
import {  Spin } from 'antd';
import FilterTable from './FilterableTable';
import { useLocation } from 'react-router-dom';



export default function LeaderBoard() {
  const [datas, setDatas] = useState([])
  const [loading, setLoading] = useState(true)
  const { active, isLoadingMain, getLeaderBoard } = useLeaderboard()
  const location = useLocation()


  const setGlobalStat = async() =>{
    const dataset = await getLeaderBoard()    
    setDatas(dataset)
  }


  useEffect(() => {
    if (location.state){
      setDatas(location.state.data)
      setLoading(false)
    } else {
      setGlobalStat()
      setLoading(false)
    }
  }, [isLoadingMain, active, location?.state])


  const resolveIcon = (item) => {
    if (item.p == 'youtube') {
      return <YoutubeFilled />
    }
    else if (item.p == 'tiktok') {
      return <TikTokFilled />

    }
    else {
      return <ExperimentFilled />
    }
  }

  if (loading) return (
    <div className='flex flex-col justify-center items-center min-h-[100%]'>
      <Spin size='lg' />
    </div>
  )
  else {
    return (
      <div className='flex flex-col items-center min-h-[100%]'>
        <FilterTable filtered={datas} />
      </div>
    );
  }

}

